import React, { useState, useEffect } from 'react';
import './styleFavoris.css'
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import { CardFavoris } from '../../Components/Card/Card';
import Article from '../../assets/Data/Article.json';
import { apiArticle, entete, apiFavorisByUser } from '../../Components/API_URL/ApiUrl';
import { CardItemFavories } from '../../Components/FlashCard/FlashCard';


const Favoris = () => {
    const [isLoading, setIsLoading] = useState(true);
    const id = localStorage.getItem('id');
    const [search, setSearch] = useState("");

    const handleSearchInput = (e) => {
        setSearch(e.target.value);
    };

    //Data Article
    const [ids, setIds] = useState(0);
    const [favories, setFavories] = useState([]);
    const [prixPromo, setPrixPromo] = useState(0);
    const [img_art, setImg_art] = useState(null);

    // Afficher tous les articles
    {/* const allfavories = async () => {
        setIsLoading(true);
        let token = localStorage.getItem('token');
        let id = localStorage.getItem('id');
        try {
            apiFavorisByUser.get(`/${id}`, {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log(res.data.data)
                setFavories(res.data.data);
                setPrixPromo(res.data.data);
                setImg_art(res.data.data);
            })
        } catch (error) { 
            if (error.response) {
                if (error.response.status === 404) {
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    console.log(error);
                }
            } else {
                console.log(error);
            }
        }
    }; */}
    const allfavories = async () => {
        setIsLoading(true);
        let token = localStorage.getItem('token');
        try {
            const res = await apiFavorisByUser.get(`/${id}`, {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(res.data.data);
            setFavories(res.data.data);
            setPrixPromo(res.data.data);
            setImg_art(res.data.data);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
        }
    };
    

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
        allfavories();
    }, []);

    if (isLoading) {
        return (
            <div style={{width: "100%", height: "100vh"}}>
                <div class="center loaderpanel">
                    <div class="ring" style={{width: "200px", height: "200px"}}></div>
                </div>
            </div>
        );
    }

    return (
        <div style={{height: "100vh"}}>
            {
                id ?
                <div style={{display: 'flex', flexDirection: "column", height: "100%", justifyContent: "space-between"}}>
                    <div className='header'>
                        <Head/>
                        <Header
                            search = {search}
                            handleSearchInput = {handleSearchInput}
                        />
                        <NavBar/>
                    </div>
    
                    <div className='body'>
                        <div className='containerPanelFlash'>
                            <p className='panelFlash'>Les Favoris</p>
                        </div>
                        
                        <div style={{marginBottom: "20px"}}>
                            {/* -------------SECTION CARD ARTICLE START------------- */}
                            <div className='equipementView' style={{justifyContent: "flex-start"}}>
                                <CardItemFavories 
                                    productItems={favories} 
                                    search={search}
                                />
                            </div>
                            <br/><br/>
                            {/* -------------SECTION CARD ARTICLE END------------- */}
                        </div>
                    </div>
    
                    <div className='footer'>
                        <Footer/>
                    </div>
                </div>
                :
                <div className='containerMateriel'>
                    <div className='header'>
                        <Head/>
                        <Header
                            search = {search}
                            handleSearchInput = {handleSearchInput}
                        />
                        <NavBar/>
                    </div>
    
                    <div className='body'>
                        <div className='containerPanelFlash'>
                            <p className='panelFlash'>Les Favoris</p>
                        </div>
                        
                        <div style={{marginBottom: "20px", height: "30vh"}}>
                            {/* -------------SECTION CARD ARTICLE START------------- */}
                            <div className='equipementView'>
                                <CardItemFavories 
                                    productItems={favories} 
                                    search={search}
                                />
                            </div>
                            <br/><br/>
                            {/* -------------SECTION CARD ARTICLE START------------- */}
                        </div>
                    </div>
    
                    <div className='footer'>
                        <Footer/>
                    </div>
                </div>
            }
        </div>
    );
};

export default Favoris;