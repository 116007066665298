import React, { useEffect } from "react";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";

const Kkia = (props) => {
  {/*useEffect(() => {
    const handlePaymentSuccess = (response) => {
      console.log(response);
    };

    addKkiapayListener("success", handlePaymentSuccess);

    return () => {
      removeKkiapayListener("success", handlePaymentSuccess);
    };
  }, []);*/}

  const openPaymentWidget = () => {
    const paymentOptions = {
      amount: props.price,
      api_key: "9d3d6740efcb11edb6d257227183d560",
      sandbox: true,
      email: "exemple@gmail.com",
      phone: "97000000",
    };

    openKkiapayWidget(paymentOptions);
  };


  return (
    <div>
        <button className="paiementBtn" style={{width: "100%"}} onClick={openPaymentWidget}>
          Paiement par Mobile money/Carte Bancaire
        </button>
    </div>
  );
};

export default Kkia;
