import React, { useState, useEffect } from 'react';
import './styleDetail.css';
import Swal from 'sweetalert2';
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import Prod from '../../assets/img1.png'
import Breadcrumb from 'rsuite/Breadcrumb';
import Rate from 'rsuite/Rate';
import {GoPlus} from 'react-icons/go';
import { FaMinus } from 'react-icons/fa';
import { BsFillHeartFill } from 'react-icons/bs';
import { GrLikeFill } from 'react-icons/fc'
import { Link, useParams } from 'react-router-dom';
import { apiArticle, entete, apiFavoris, apiFavorisByArticle, notify, fCurrencyDot } from '../../Components/API_URL/ApiUrl';
import { URL_Local, URL } from '../../Components/API_URL/ApiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCard } from '../Panier/redux/action/Actions';
import { ToastContainer } from 'react-toastify';

const Detail = () => {
    const [status, setStatus] = useState();
    const [search, setSearch] = useState("");
	const[quantities, setQuantities] = useState(1)
	const[iconbtn, setIconbtn] = useState(false)
	const { id_art } = useParams();

	/*const handleButton = () => {
		setIconbtn(!iconbtn)
	}*/

    const handleSearchInput = (e) => {
    	setSearch(e.target.value);
    };

    // Dispatch
    const dispatch = useDispatch()

    const items = useSelector(state => state.Reducers.items);
    // console.log("KKK :" + (items ? items.length : 0));
    let addedItems = [];
    addedItems = items || [];
    console.log('Length: ' + addedItems.length);

    // Basket
    const addItem = (item) => {
        dispatch(addItemToCard(item));
    };

    const [idfavoris, setIdFavoris] = useState(0);
    const [idfavorisArt, setIdFavorisArt] = useState(0);
    const [favorisArt, setFavorisArt] = useState([]);

	const [ids, setIds] = useState(0);
    const [article, setArticle] = useState([]);
    const [nomarticle, setNomarticle] = useState('');
    const [description, setDescription] = useState('');
    const [prix, setPrix] = useState(0);
    const [prix_promo, setPrix_promo] = useState(0);
    const [imgArt, setImg_art] = useState(null);
    const [souscategorie, setSousCategorie] = useState('');
    const [souscategorieID, setSousCategorieID] = useState('');
    const [categorie, setCategorie] = useState('');
    const [categorieID, setCategorieID] = useState('');
    const [etat, setEtat] = useState('');

	// Ajout de Favoris
    const ajoutFavoris = async (e) => {
        e.preventDefault();
        let id = localStorage.getItem('id');
        let token = localStorage.getItem('token');

        if (nomarticle && token) {
            try{
                //alert(libsouscat)
                await apiFavoris.post('/', 
                { 
                    img_art: imgArt,
                    nomarticle, prix, etat, 
                    user_ID: id,
                    article_ID: ids,
                }, {
                    headers: {
                        entete
                    }
                }).then(res => {
                    console.log(res.data)
                    setStatus(true);
                    setArticle([]);
                    setStatus(true);
                    setFavorisArt([]);
                    notify('success', `${res.data.message}`)
                }).catch((err) => {
                    console.log(err);
                    notify('warning', `L'article existe déjà dans les favories`)
                })
            } catch (err) {
                console.error(err);
                notify('', `Une erreur s'est produite lors de l'ajout dans les favoris. Assurez-vous que les champs sont bien renseignés`)
            }
        } else {
            notify('warning', `Connectez-vous d'abord`)
        }
    };

	// Supprimer le favoris
    const deleteArticle = async (idfavoris) => {
        let token = localStorage.getItem('token');
        try {
            apiFavoris.delete(`/${idfavoris}`, {
                id: idfavoris,
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                // alert(res.data)
                setArticle([]);
                setFavorisArt([]);
                setStatus(true);
                notify('success', `${res.data.message}`)
            })
        } catch (error) { throw error; }
    }

    const getDataFavorisByIdArticle = async () => {
        let token = localStorage.getItem('token');
        try {
          const { data } = await apiFavorisByArticle.get(`/${id_art}`, {
            headers: {
              entete,
              Authorization: `Bearer ${token}`
            }
          });
          console.log(data.data[0])
          setFavorisArt(data.data[0])
          setIdFavoris(data.data[0].id)
          setIdFavorisArt(data.data[0].article_ID)
          setStatus(true)
          //alert(data.data[0].article_ID)
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
        }
    };

    // Vérifie si l'article est déjà présent dans le panier
    const itemInCart = addedItems.find((cartItem) => cartItem.id_art === article.id_art);

	useEffect(() => {
		const getDataById = async () => {
			const { data } = await apiArticle.get(`/${id_art}`, {
				headers: {
					entete
				}
			})
			console.log(data)
            setArticle(data.data)
			setIds(data.data.id_art)
			setNomarticle(data.data.nomarticle)
			setDescription(data.data.description)
			setPrix(data.data.prix)
			setPrix_promo(data.data.prix_promo)
			setCategorie(data.data.Categorie.nomcat)
			setCategorieID(data.data.Categorie.id)
			setSousCategorie(data.data.SousCategorie.libsouscat)
			setSousCategorieID(data.data.SousCategorie.id)
			setImg_art(data.data.img_art)
			setEtat(data.data.etat)
		}

        getDataById();
        getDataFavorisByIdArticle();
    }, [id_art])

    return (
        <div className='containerMateriel'>
            <div className='header'>
                <Head/>
                <Header
                    search = {search}
                    handleSearchInput = {handleSearchInput}
                />
                <NavBar/>
            </div>
            <ToastContainer 
                position="top-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className='bodyDetail'>
				<div className='containerdetail'>
					<div>
						<Breadcrumb>
							<Breadcrumb.Item href="/" style={{textDecoration: "none"}}>Accueil</Breadcrumb.Item>
							<Breadcrumb.Item href={`/equipement/${categorieID}`} style={{textDecoration: "none"}}>{categorie}</Breadcrumb.Item>
							<Breadcrumb.Item href={`/materiel/${souscategorieID}/${categorieID}`} style={{textDecoration: "none"}}>{souscategorie}</Breadcrumb.Item>
							<Breadcrumb.Item active  style={{textDecoration: "none"}}>{nomarticle}</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					<div className='containerDetailProduit'>
						<div className='containerProd'>
							<div className='imgProd'>
								{imgArt ? (
									<img src={`${URL_Local}/image/` + imgArt} alt='img' />
								) : (
									<img src={require("../../assets/produit.jpg")} alt='default img' />
								)}
							</div>
							{/* <div className='containerImgProdSmall'>
								<div className='imgProdSmall'>
									<img src={imgArt ? (`${URL_Local}/image/` + imgArt) : require("../../assets/produit.jpg")} alt={imgArt ? 'img' : 'default img'} />
								</div>
								<div className='imgProdSmall'>
									<img src={imgArt ? (`${URL_Local}/image/` + imgArt) : require("../../assets/produit.jpg")} alt={imgArt ? 'img' : 'default img'} />
								</div>
								<div className='imgProdSmall'>
									<img src={imgArt ? (`${URL_Local}/image/` + imgArt) : require("../../assets/produit.jpg")} alt={imgArt ? 'img' : 'default img'} />
								</div>
								<div className='imgProdSmall'>
									<img src={imgArt ? (`${URL_Local}/image/` + imgArt) : require("../../assets/produit.jpg")} alt={imgArt ? 'img' : 'default img'} />
								</div>
                                </div> */}
						</div>

						<div className='containerRightDetail'>
							<p style={{fontSize: "20px", fontWeight: "bold"}}>{nomarticle}</p>
							<span style={{textAlign: 'justify'}}>
								{description}
							</span>
                            {
                                prix_promo !== 0 ?
                                <div className='containerPrice'>
                                    <h4 id='price'>{fCurrencyDot(prix)} F CFA</h4>
                                    <h4>{fCurrencyDot(prix_promo)} F CFA</h4>
                                </div>
                                :
                                <div className='containerPrice'>
                                    <h4>{fCurrencyDot(prix)} F CFA</h4>
                                </div>
                            }
							<div className='descriptionProd'>
								<div className='btnDetail'>
                                    {itemInCart ? 
									    <Link className='cardBtnPanierV'>Article ajouter</Link>
                                        :
									    <Link onClick={() => !itemInCart && addItem(article)} className='cardBtnPanier'>Ajouter au panier</Link>
                                    }
									
                                    {
                                        idfavorisArt ?
										<button onClick={() => deleteArticle(idfavoris)} className='containerIconDetail' style={{backgroundColor: "red", color: "#FFFFFF"}}>
											<BsFillHeartFill size={20} color='#FFFFFF'/>
										</button>
                                        :
                                        <button onClick={ajoutFavoris} className='containerIconDetail'>
											<BsFillHeartFill size={20} color='red'/>
										</button>
									}
                                    
                                   {/* {
                                        idfavorisArt ?
										<button onClick={() => deleteArticle(idfavorisArt)} className='containerIconDetail' style={{backgroundColor: "red", color: "#FFFFFF"}}>
											<BsFillHeartFill size={20} color='#FFFFFF'/>
										</button>
                                        :
                                        <div></div>
                                    } */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

            <div className='footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default Detail;