import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { URL_Local, fCurrencyDot } from "../API_URL/ApiUrl";
import { Link } from "react-router-dom";
import Empty from "../../assets/png/undraw_Web_search_re_efla.png";

const SampleNextArrow = (props) => {
  const { onClick } = props
  return (
    <div className='control-btn' onClick={onClick}>
      <button className='next'>
        <i className='fa fa-long-arrow-alt-right'></i>
      </button>
    </div>
  )
}
const SamplePrevArrow = (props) => {
  const { onClick } = props
  return (
    <div className='control-btn' onClick={onClick}>
      <button className='prev'>
        <i className='fa fa-long-arrow-alt-left'></i>
      </button>
    </div>
  )
}
export const FlashCard = ({ productItems, addToCart }) => {
  const [slidesToShow, setSlidesToShow] = useState(4);
  const [active, setActive] = useState(false);
  const handleActive = (id_art) => {
    setActive(id_art)
  }
  const activeClass = (id_art) => {
      return active === id_art ? 'active' : '';
  };
  // handling Add-to-cart
  const handleAddItem = (productItems) => {
    addToCart(productItems)
    handleActive(productItems.id_art)
    setTimeout(() => {
      handleActive(false);
    }, 2000);
  }

  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSlidesToShow(2);
        } else if (window.innerWidth <= 1200) {
            setSlidesToShow(3);
        } else {
            setSlidesToShow(4);
        }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  return (
    <>
      <Slider {...settings}>
        {
          productItems.map((productItems) => {
          return (
            <div className='box'>
              <div className='product mtop'>
                  <div className='img'>
                      {
                        productItems.prix_promo !== 0 ?
                          <span className='discount'>
                            {fCurrencyDot(productItems.prix_promo)} F CFA
                          </span>
                        :
                        ""
                      }
                      <img src={`${URL_Local}/image/${productItems.img_art}`} alt='' className="imgWidth" />
                      <div className='product-like'>
                        <br/><br/><br/><br/>
                        <div className='product-info'>
                          <Link to={`/materiel/detail/${productItems.id_art}`} style={{textDecoration: 'none'}} className="product-view-detail">
                            Voir détail
                          </Link>
                        </div>
                      </div>
                  </div>
                  <div className='product-details'>
                      <h3>
                          {productItems.nomarticle.length > 10
                          ? `${productItems.nomarticle.slice(0, 20)}...`
                          : productItems.nomarticle}
                      </h3>              
                      <div className='rate'>
                          {productItems.description.length > 10
                          ? `${productItems.description.slice(0, 20)}...`
                          : productItems.description}
                      </div>
                      <div className='price'>
                          {
                              productItems.prix_promo !== 0 ?
                                  <h4 style={{textDecoration: "line-through", fontSize: "1em"}}>{fCurrencyDot(productItems.prix)} F CFA </h4>
                              :
                              <h4 style={{fontSize: "1em"}}>{fCurrencyDot(productItems.prix)} F CFA </h4>
                          }
                          {/* step : 3  
                              if hami le button ma click garryo bahne 
                          */}
                          <button 
                            className={`${activeClass(productItems.id_art)}`} 
                            onClick={() => { handleAddItem(productItems)} }
                          >
                            <i className='fa fa-plus'></i>
                          </button>
                      </div>
                  </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </>
  )
}

export const FlashCard2 = ({ productItems }) => {
  const [slidesToShow, setSlidesToShow] = useState(4);

  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSlidesToShow(2);
        } else if (window.innerWidth <= 1200) {
            setSlidesToShow(3);
        } else {
            setSlidesToShow(4);
        }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  return (
    <>
      <Slider {...settings}>
        {productItems.map((productItems) => {
          return (
            <div className='box'>
                <div className='product mtop'>
                    <div className='img'>
                        {
                            productItems.etat ?
                                <span className='discount'>
                                  {productItems.etat}
                                </span>
                            :
                            ""
                        }
                        <img src={`${URL_Local}/image/${productItems.img_art}`} alt='' className="imgWidth" />
                        <div className='product-like'>
                          <br/><br/><br/><br/>
                          <div className='product-info'>
                            <Link to={`/materiel/detail/${productItems.id_art}`} style={{textDecoration: 'none'}} className="product-view-detail">
                              Voir détail
                            </Link>
                          </div>
                        </div>
                    </div>
                    <div className='product-details'>
                        <h3>
                            {productItems.nomarticle.length > 10
                            ? `${productItems.nomarticle.slice(0, 20)}...`
                            : productItems.nomarticle}
                        </h3>              
                        <div className='rate'>
                            {productItems.description.length > 10
                            ? `${productItems.description.slice(0, 20)}...`
                            : productItems.description}
                        </div>
                        <div className='price'>
                            {
                                productItems.prix_promo !== 0 ?
                                  <h4 style={{textDecoration: "line-through", fontSize: "1em"}}>{fCurrencyDot(productItems.prix_promo)} F CFA </h4>
                                :
                                <h4 style={{fontSize: "1em"}}>{fCurrencyDot(productItems.prix)} F CFA </h4>
                            }
                        </div>
                    </div>
                </div>
            </div>
          )
        })}
      </Slider>
    </>
  )
}

export const CardItem = ({ productItems, addToCart, search }) => {
  const [active, setActive] = useState(false);
  const handleActive = (id_art) => {
    setActive(id_art)
  }
  const activeClass = (id_art) => {
      return active === id_art ? 'active' : '';
  };
  // handling Add-to-cart
  const handleAddItem = (productItems) => {
    addToCart(productItems)
    handleActive(productItems.id_art)
    setTimeout(() => {
      handleActive(false);
    }, 2000);
  }

  return (
    <>
      {
        productItems.length !== 0 ?
          <>
            {productItems.filter((productItem) => {
              if (search === "") {
                return true; // Inclure l'élément si la recherche est vide
              } else if (
                productItem.statut &&
                productItem.statut.toLowerCase().includes(search.toLowerCase())
              ) {
                return true; // Inclure l'élément si le statut correspond à la recherche (ignorer la casse)
              } else {
                return false; // Exclure l'élément si le statut ne correspond pas à la recherche
              }
            })
            .map((productItems) => {
              return (
                <div className='productItem mtop'>
                  <div className='img'>
                    {
                      productItems.prix_promo !== 0 ?
                        <span className='discount'>
                          {fCurrencyDot(productItems.prix_promo)} F CFA
                        </span>
                      :
                      ""
                    }
                    <img src={`${URL_Local}/image/${productItems.img_art}`} alt='' className="imgWidth" />
                    <div className='product-like'>
                      <div className='product-info'>
                        <br/><br/><br/><br/>
                        <Link to={`/materiel/detail/${productItems.id_art}`} style={{textDecoration: 'none'}} className="product-view-detail">
                          Voir détail
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='product-details'>
                    <h3>
                        {productItems.nomarticle.length > 10
                        ? `${productItems.nomarticle.slice(0, 20)}...`
                        : productItems.nomarticle}
                    </h3>              
                    <div className='rate'>
                        {productItems.description.length > 10
                        ? `${productItems.description.slice(0, 20)}...`
                        : productItems.description}
                    </div>
                    <div className='price'>
                        {
                            productItems.prix_promo !== 0 ?
                                <h4 style={{textDecoration: "line-through", fontSize: "1em"}}>{fCurrencyDot(productItems.prix)} F CFA </h4>
                            :
                            <h4 style={{fontSize: "1em"}}>{fCurrencyDot(productItems.prix)} F CFA </h4>
                        }
                        <button 
                          className={`${activeClass(productItems.id_art)}`} 
                          onClick={() => { handleAddItem(productItems)} }
                        >
                          <i className='fa fa-plus'></i>
                        </button>
                    </div>
                    <div className='price'>
                      <button 
                        className={`btnDisplayPhone ${activeClass(productItems.id_art)}`} 
                        onClick={() => { handleAddItem(productItems)} }
                      >
                        <i className='fa fa-plus'></i>
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
          </>
        :
        <div style={{display: 'flex', justifyContent: "center", alignItems: "center", width: "100%"}}>
          <img src={Empty} alt='img' width="30%" height="20%"/>
        </div>
      }
    </>
  )
}

export const CardItemFavories = ({ productItems, search }) => {

  return (
    <>
      {
        productItems.length !== 0 ?
          <>
            {productItems.filter((productItem) => {
              if (search === "") {
                return true; // Inclure l'élément si la recherche est vide
              } else if (
                productItem.statut &&
                productItem.statut.toLowerCase().includes(search.toLowerCase())
              ) {
                return true; // Inclure l'élément si le statut correspond à la recherche (ignorer la casse)
              } else {
                return false; // Exclure l'élément si le statut ne correspond pas à la recherche
              }
            })
            .map((productItems) => {
              return (
                <div className='box cardItem'>
                    <div className='product mtop'>
                      <div className='img'>
                          {
                              productItems.Article.prix_promo !== 0 ?
                                <span className='discount'>
                                  {fCurrencyDot(productItems.Article.prix_promo)} F CFA
                                </span>
                              :
                              ""
                          }
                          <img src={`${URL_Local}/image/${productItems.img_art}`} alt='' className="imgWidth" />
                          <div className='product-like'>
                            <div className='product-info'>
                              <br/><br/><br/><br/>
                              <Link to={`/materiel/detail/${productItems.Article.id_art}`} style={{textDecoration: 'none'}} className="product-view-detail">
                                Voir détail
                              </Link>
                            </div>
                          </div>
                      </div>
                      <div className='product-details'>
                          <h3>
                              {productItems.nomarticle.length > 10
                              ? `${productItems.nomarticle.slice(0, 20)}...`
                              : productItems.nomarticle}
                          </h3>    
                          <div className='price'>
                              {
                                  productItems.Article.prix_promo !== 0 ?
                                    <h4 style={{textDecoration: "line-through", fontSize: "1em"}}>{fCurrencyDot(productItems.Article.prix)} F CFA </h4>
                                  :
                                  <h4 style={{fontSize: "1em"}}>{fCurrencyDot(productItems.Article.prix)} F CFA </h4>
                              }
                          </div>
                      </div>
                    </div>
                </div>
              )
            })}
          </>
        :
        <div style={{display: 'flex', justifyContent: "center", alignItems: "center", width: "100%"}}>
          <img src={Empty} alt='img' width="30%" height="20%"/>
        </div>
      }
    </>
  )
}