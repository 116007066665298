import React, { useState, useEffect } from 'react';
import './stylePanier.css';
import '../../Components/Panier/style.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import Breadcrumb from 'rsuite/Breadcrumb';
import Article from '../../assets/Data/Article.json';
import {GoPlus} from 'react-icons/go';
import { FaMinus } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Input } from 'rsuite';
import InputPicker from 'rsuite/InputPicker';
import { useSelector, useDispatch } from 'react-redux';
import { removeItemToCard, resetItems } from '../Panier/redux/action/Actions';
import { apiPanier, entete, notify } from '../../Components/API_URL/ApiUrl';
import { Table } from 'rsuite';
import { URL_Local, fCurrencyDot, apiPays } from '../../Components/API_URL/ApiUrl';
import { ToastContainer } from 'react-toastify';
import { Form, Button } from 'react-bootstrap';



const { Column, HeaderCell, Cell } = Table;

const Panier = () => {
    const [totalP, setTotal] = useState(0);
    const [status, setStatus] = useState();
    const [totals, setTotals] = useState({});
    const [selectedCountry, setSelectedCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [prixExpediton, setPrixExpediton] = useState(750);
    const [selectedState, setSelectedState] = useState("");


    const dispatch = useDispatch();
    const items = useSelector(state => state.Reducers.items);
    console.log("Basket1: " + items);
    console.log("Basket2: " + JSON.stringify(items, null, 2));

    let addedItems = [];
    addedItems = items || [];
    console.log('Length: ' + addedItems.length);
    console.log('Length444: ' + addedItems);


    const [quantities, setQuantities] = useState(new Array(items.length).fill(0));

    {/*const total = addedItems.reduce((acc, item, index) => { 
        item.prix_promo !== 0 ?
        acc + item.prix * quantities[index], 0
        :
        acc + item.prix * quantities[index], 0
    });*/}
    const total = addedItems.reduce((acc, item, index) => { 
        const prixUnitaire = item.prix_promo !== 0 ? item.prix_promo : item.prix;
        return acc + prixUnitaire * quantities[index];
    }, 0);    

    const removeItem = (index) => {
        dispatch(removeItemToCard(index))
    };


    const onMinus = (index) => {
        const newQuantities = [...quantities];
        newQuantities[index] = Math.max(newQuantities[index] - 1, 0);
        setQuantities(newQuantities);
    };
    const onPlus = (index) => {
        const newQuantities = [...quantities];
        newQuantities[index] = newQuantities[index] + 1;
        setQuantities(newQuantities);
    };

    let qte = [];
    let totalByArcticle = [];
    for (let i = 0; i < items.length; i++) {
        qte[i] = quantities[i];
        totalByArcticle[i] = items[i].prix * quantities[i];
    }

    const ajoutPanier = async (e) => {
        e.preventDefault();
        e.persist()
        let token = localStorage.getItem('token');
        let userID = localStorage.getItem('id');
        // let codePromo = e.target.codePromo.value;
        let Adresse = e.target.Adresse.value;
        let codePostal = e.target.codePostal.value;
        let prixTotal = total + prixExpediton;
        let states = e.target.states.value;     
        const itemsWithQuantity = items.map((item, index) => {
            const qte = quantities[index];
            const prixUnitaire = item.prix_promo !== 0 ? item.prix_promo : item.prix;
          
            return {
              ...item,
              qte: qte,
              totalByArcticle: prixUnitaire * qte,
            };
        });     
        console.log(items)
        
        try {
            await apiPanier.post('/', { 
                items: itemsWithQuantity, 
                userID ,
                preTotal: total,
                prixExpediton: prixExpediton,
                pays: selectedCountry,
                etatProvince: states,
                Adresse: Adresse,
                prixTotal: prixTotal,
                codePostal : codePostal
            }, {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                if (res) {
                    // Réinitialiser le state `items`
                    dispatch(resetItems());
                    // codePromo = "";
                    setStatus(true)
                    notify('success', "La liste de votre panier a été ajouté avec succes et est en cours")
                    window.location.href = "/livraison";
                } else {
                    notify('warning', `${res.data.message}`)
                }
            }).catch((err) => {
                console.log(err);
                if (err.response && err.response.data && err.response.data.message) {
                    notify('warning', err.response.data.message);
                } else {
                    notify('warning', 'Une erreur s\'est produite');
                }
            })
        } catch (error) { 
            console.log(error)
            notify('', 'Echec de validation du panier')
        }
    };
      
    let tableData = [];
    for (let i = 0; i < items.length; i++) {
        tableData.push({ ...items[i], index: i });
    }

    const handleCountryChange = (event) => {
        const countryName = event.target.value;
        setSelectedCountry(countryName);
    };

    const allpays = async () => {
        try {
          const response = await apiPays.get('/', {
            headers: {
              entete
            }
          });
          console.log(response.data.data.pays);
          setCountries(response.data.data.pays);
        } catch (error) {
          console.log(error);
          throw error;
        }
    }

    const handleStateChange = (event) => {
        const stateName = event.target.value;
        setSelectedState(stateName);
    };

    useEffect(() => {
        allpays();
    }, []);
    useEffect(() => {
        setQuantities(Array(addedItems.length).fill(1));
    }, [addedItems]);

    return (
        <div className='containerMateriel'>
            <div className='header'>
                <Head/>
                <Header/>
                <NavBar/>
            </div>
            <ToastContainer 
                position="top-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className='containerBodyPanier'>
                <Breadcrumb>
                    <Breadcrumb.Item href="/" style={{textDecoration: "none"}}>Accueil</Breadcrumb.Item>
                    <Breadcrumb.Item active style={{textDecoration: "none"}}>Panier</Breadcrumb.Item>
                </Breadcrumb>

                <h2 className='nbrProduit'>Votre panier ({addedItems.length} articles)</h2>

                <section className='cart-items'>
                    <div className='container conteneurList'>
                        {/* if hamro cart ma kunai pani item xaina bhane no display */}
                        <div className='containerScroll'>
                            <div className=' detailPanier'>
                                {tableData.length === 0 && <h1 className='no-items product' style={{textAlign: 'center'}}>Aucun article n'est ajouté au panier</h1>}
                    
                                {/* Yasma hami le cart item lai display garauxa */}
                                {tableData.map((item) => {
                                    const qty = quantities[item.index];
                                    const prixUnitaire = item.prix_promo !== 0 ? item.prix_promo : item.prix;
                                    const totalByArticle = isNaN(qty) ? 0 : prixUnitaire * qty;
                    
                                    return (
                                        <div className='cart-list product itemsListe' key={item.id_art}>
                                            <div className='cart-list d_flex' style={{width: '100%'}}>
                                                <div className='img'>
                                                    <img src={`${URL_Local}/image/` + item.img_art} alt='' />
                                                </div>
                                                <div className='cart-details'>
                                                    <h3>{item.nomarticle}</h3>
                                                    <p>{item.description}</p>
                                                    <span>
                                                        {`${fCurrencyDot(prixUnitaire)} F CFA`} * {isNaN(qty) ? 0 : qty}
                                                        <span>{fCurrencyDot(totalByArticle)} F CFA</span>
                                                    </span>
                                                </div>
                                                <div className='cart-items-function'>
                                                    <div className='removeCart'>
                                                        <button className='removeCart' onClick={() => removeItem(item.index)}>
                                                        <i className='fa-solid fa-xmark'></i>
                                                        </button>
                                                    </div>
                                                    {/* Step: 5
                                                        Increment and Decrement product quantity
                                                    */}
                                                    <div className='cartControl d_flex'>
                                                        <button className='incCart' onClick={() => onPlus(item.index)}>
                                                            <i className='fa-solid fa-plus'></i>
                                                        </button>
                                                        <button className='desCart' onClick={() => onMinus(item.index)}>
                                                            <i className='fa-solid fa-minus'></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='cart-item-price'></div>
                                            </div>
                                            
                                            <div className='cart-list cart-detailsMobile'>
                                                <h3>{item.nomarticle}</h3>
                                                <p>{item.description}</p>
                                                <span>
                                                    {`${fCurrencyDot(prixUnitaire)} F CFA`} * {isNaN(qty) ? 0 : qty}
                                                    <span>{fCurrencyDot(totalByArticle)} F CFA</span>
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {items != 0 &&
                            <form  onSubmit={ajoutPanier} className='cart-total product'>
                                <h2>Information de livraison</h2>
                                
                                <div className='account'>
                                    <p>Pays</p>
                                    <select style={{border: "2px solid #f6f9fc"}} className='selectOption' id="country-select" value={selectedCountry} onChange={handleCountryChange}>
                                        <option value="">-- Sélectionner --</option>
                                        {countries.map(country => (
                                            <option key={country.id} value={country.nom}>{country.designation}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='account'>
                                    <p>Etat/Province</p>
                                    <Form.Control 
                                        type="texte" 
                                        size="lg" 
                                        placeholder="Etat/Province" 
                                        name="states" 
                                        style={{width: "70%"}} 
                                    />
                                </div>
                                <div className='account'>
                                    <p>Adresse</p>
                                    <Form.Control 
                                        type="texte" 
                                        size="lg" 
                                        placeholder="Adresse" 
                                        name="Adresse" 
                                        style={{width: "70%"}} 
                                    />
                                </div>
                                <div className='account'>
                                    <p>Code postal</p>
                                    <Form.Control 
                                        type="texte" 
                                        size="lg" 
                                        placeholder="Code Postal" 
                                        name="codePostal" 
                                        style={{width: "70%"}} 
                                    />
                                </div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <h4>Total Prix :</h4>
                                    <h3>&nbsp;&nbsp;{fCurrencyDot(total)} F CFA</h3>
                                </div>
                                <button type='submit' className='paiementBtn'>Effectuer le paiement</button>
                            </form>
                        }
                    </div>
                </section>
            </div>
            
            <div className='footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default Panier;