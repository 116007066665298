import axios from "axios";
import { toast } from 'react-toastify';
import numeral from 'numeral';


//Entete
export const entete = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
}
export const entetes = {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
}

// NOTIFY START ----------------------------------------------------
export const notify = (type, text) => {
    switch (type) {
        case 'success':
            toast.success(text)
            break;
        case 'warning':
            toast.warning(text)
            break;
        case 'info':
            toast.info(text)
            break;
        default:
            toast.error(text)
            break;
    }
}
// NOTIFY END ----------------------------------------------------

// FORMAT NUMBER ----------------------------------------------------
// Configuration des paramètres régionaux de Numeral.js pour utiliser un espace comme séparateur de milliers
numeral.register('locale', 'fr', {
    delimiters: {
        thousands: ' ',
        decimal: '.',
    },
    abbreviations: {
        thousand: 'K',
        million: 'M',
        billion: 'B',
        trillion: 'T',
    },
    ordinal: (number) => {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: '€',
    },
});
  
  // Définir les paramètres régionaux par défaut de Numeral.js sur 'fr'
  numeral.locale('fr');

  // -----------------------------
export function fNumber(number) {
    return numeral(number).format();
}
export function fCurrencyDot(number) {
    const format = number ? numeral(number).format('0,0') : '';
    return format;
}
export function fCurrency(number) {
    const format = number ? numeral(number).format('0,0.00') : '';
    return result(format, '.00');
}
export function fPercent(number) {
    const format = number ? numeral(Number(number) / 100).format('0.0%') : '';
    return result(format, '.0');
}
function result(format, key = '.00') {
    const isInteger = format.includes(key);
    return isInteger ? format.replace(key, '') : format;
}
// FORMAT NUMBER ----------------------------------------------------

// API START ----------------------------------------------------
export const URL = "https://backend.africaglobaleservice.com";
export const URLs = "https://backend.africaglobaleservice.com";
export const URL_Local = "https://backend.africaglobaleservice.com";
// export const URL_Local = "https://backend.africaglobaleservice.com/";

// AUTH
export const apiAuth = axios.create({
    baseURL: `${URL_Local}/api/login`
})

// SIGN UP
export const apiSignUp = axios.create({
    baseURL: `${URL_Local}/api/user`
})

// USERS
export const apiUser = axios.create({
    baseURL: `${URL_Local}/api/userAdmin`
})

// USER PERMISSION
export const apiUserPermission = axios.create({
    baseURL: `${URL_Local}/api/permission`
})

//// SIGN UP
export const apiContactMail = axios.create({
    baseURL: `${URL_Local}/api/email`
})

// SOUS CATEGORIE
export const apiSousCat = axios.create({
    baseURL: `${URL_Local}/api/souscategorie`
})

// SOUS CATEGORIE BY CATEGORIE
export const apiSousCatByCategorie = axios.create({
    baseURL: `${URL_Local}/api/souscategorie/categorie`
})

// CATEGORIE
export const apiCategorie = axios.create({
    baseURL: `${URL_Local}/api/categorie`
})

// ARTICLE DONT LE STOCK EXISTE
export const apiArticle = axios.create({
    baseURL: `${URL_Local}/api/articleStockExiste`
})

// ARTICLE 
export const apiArticleWithAllStock = axios.create({
    baseURL: `${URL_Local}/api/article`
})

// ARTICLE BY CATEGORIE 
export const apiArticleByCategorie = axios.create({
    baseURL: `${URL_Local}/api/article/categorie`
})


// PANIER
export const apiPanier = axios.create({
    baseURL: `${URL_Local}/api/panier`
})

// STOCK
export const apiStock = axios.create({
    baseURL: `${URL_Local}/api/stock`
})

// STOCK BY ARTICLE
export const apiStockByArticle = axios.create({
    baseURL: `${URL_Local}/api/stock/article`
})

// A PROPOS
export const apiApropos = axios.create({
    baseURL: `${URL_Local}/api/apropos`
})

// A PROPOS
export const apiContenuImg = axios.create({
    baseURL: `${URL_Local}/api/dataApp`
})

// SLIDE
export const apiContenuSlide = axios.create({
    baseURL: `${URL_Local}/api/slide`
})

// Favoris
export const apiFavoris = axios.create({
    baseURL: `${URL_Local}/api/favoris`
})

// Favoris By Article
export const apiFavorisByArticle = axios.create({
    baseURL: `${URL_Local}/api/favoris/article`
})

// Favoris By User
export const apiFavorisByUser = axios.create({
    baseURL: `${URL_Local}/api/favoris/user`
})

// FEDAPAY
export const apiFedapay = axios.create({
    baseURL: `${URL_Local}/api/fedapay/transaction`
})

// RAPPORT
export const apiRapportUser = axios.create({
    baseURL: `${URL_Local}/api/users/count`
})

// FACTURE
export const apiFacture = axios.create({
    baseURL: `${URL_Local}/api/facture`
})

// LIVRAISON
export const apiLivraison = axios.create({
    baseURL: `${URL_Local}/api/livraison`
})

// LIVRAISON USER EN ATTENTE
export const apiLivraisonUser = axios.create({
    baseURL: `${URL_Local}/api/livraisonUser`
})

// LIVRAISON USER VALIDER
export const apiLivraisonUserV = axios.create({
    baseURL: `${URL_Local}/api/livraisonUserValider`
})

// Gestion Paiement Menu
export const apiGestionPaiementMenu = axios.create({
    baseURL: `${URL_Local}/api/gestionpaiement`
})

// PANIER POINT DE VENTE
export const apiPanierPointVente = axios.create({
    baseURL: `${URL_Local}/api/panierpointvente`
})

// PAYS
export const apiPays = axios.create({
    baseURL: `${URL_Local}/api/pays`
})
// API END ----------------------------------------------------