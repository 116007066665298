import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './stylesAuth.css';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/AGS_logo_derive.png';
import Form from 'react-bootstrap/Form';
import { apiSignUp, entete } from '../../Components/API_URL/ApiUrl';
import { InputGroup } from 'rsuite';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';


const SignUp = () => {
    const [msg, setMsg] = useState("");
    const [visible, setVisible] = useState(false);

    const handleChange = () => {
        setVisible(!visible);
    };

    const initialData = {
        nom: "",
        prenom: "",
        adresse: "",
        email: "",
        telephone: "",
        password: "",
        type: "utilisateur"
    }

    const [userInfoc, setuserInfoc] = useState(initialData);
    const { nom, prenom, adresse, email, telephone, password, type } = userInfoc;
    const onChangeValue = (e) => {
        setuserInfoc({ ...userInfoc, [e.target.name]: e.target.value });
        // console.log(userInfo);
    }

    const signUpUser = async (e) => {
        e.preventDefault();
        e.persist()
        // console.log(email, password);
        try {
            await apiSignUp.post('/', {
                entete,
                nom: userInfoc.nom,
                prenom: userInfoc.prenom,
                adresse: userInfoc.adresse,
                email: userInfoc.email,
                telephone: userInfoc.telephone,
                password: userInfoc.password
            }).then((res) => {

                if (res) {
                    setMsg(res.data.message)
                    window.location.href = "/auth";
                }

            }).catch((err) => {
                console.log(err);
                Swal.fire({
                    title: 'Vos informations sont incorrectes',
                    icon: 'warning',
                    confirmButtonText: "",
                    confirmButtonColor: '#FFFFFF',
                    closeOnConfirm: false,
                    animation: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
        } catch (error) { throw error; }
    };



    return (
        <div className="bodyAuth">
            <div id="LoginCard">
                {/* ********************Header Login Start******************** */}
                <div className='BrandLogo'>
                    <img src={Logo} alt='logo' width="300px"/>
                </div>
                <h1 className='nameService'>Africa Global Service</h1>
                <div className="LoginHeadWrap">
                    <p className="LoginHeadTitle">S'inscrire</p>
                </div>
                {/* ********************Header Login End******************** */}

                {/* ********************Input and Button Login Start******************** */}
                <Form onSubmit={signUpUser} id="sign-in-form">
                    <div className="Inputs">
                        <Form.Control  
                            type='texte' 
                            placeholder="Nom" 
                            name='nom'
                            onChange={onChangeValue}
                            value={nom}
                            size="lg"
                        />
                    </div>
                    <div className="Inputs">
                        <Form.Control  
                            type='texte' 
                            placeholder="Prenom" 
                            name='prenom'
                            onChange={onChangeValue}
                            value={prenom}
                            size="lg"
                        />
                    </div>
                    <div className="Inputs">
                        <Form.Control  
                            type='texte' 
                            placeholder="Adresse" 
                            name='adresse'
                            onChange={onChangeValue}
                            value={adresse}
                            size="lg"
                        />
                    </div>
                    <div className="Inputs">
                        <Form.Control  
                            type='email' 
                            placeholder="Email" 
                            name='email'
                            onChange={onChangeValue}
                            value={email}
                            size="lg"
                        />
                    </div>
                    <div className="Inputs">
                        <Form.Control  
                            type='number'
                             placeholder="Telephone" 
                            name='telephone'
                            onChange={onChangeValue}
                            value={telephone}
                            size="lg"
                        />
                    </div>
                    <div className="Inputs">
                        <InputGroup inside style={{display: "flex", justifyContent: "flex-end"}}>
                            <Form.Control 
                                type={visible ? 'text' : 'password'} 
                                placeholder="Mot de passe" 
                                name="password"
                                onChange={onChangeValue}
                                value={password}
                                size="lg"
                            />
                            <InputGroup.Button onClick={handleChange} style={{height: "100%", backgroundColor: "gray"}}>
                                {visible ? <EyeIcon /> : <EyeSlashIcon />}
                            </InputGroup.Button>
                        </InputGroup>
                    </div>
                    {
                        msg ?
                            <span style={{display: "flex", flexDirection: "row", gap: "8px", color: "green", fontWeight: "bolder"}}>
                                {msg}
                                <div class="center">
                                    <div class="ring"></div>
                                </div>
                            </span>
                            :
                            null
                    }
                    <button type="submit" className="primarybuttom connecte">
                        S'inscrire
                    </button>
                </Form>
                {/* ********************Input and Button Login End******************** */}

                {/* ********************Underline Login Start******************** */}
                <div className="container_line">
                    <div className="line"></div>
                    <div className="text_line">
                        <p className="text">Or</p>
                    </div>
                </div>
                {/* ********************Underline Login End******************** */}
                {/* ********************Footer Information Login Start******************** */}
                <div className="LoginFooterWrap">
                    <div className="LoginFooterTopWrap">
                        <p>Vous avez déjà un compte?&nbsp;</p>
                        <div>
                        <NavLink className="link_get_started" to='/auth'>
                            Connectez-vous
                        </NavLink>
                        </div>
                    </div>
                </div>
                {/* ********************Footer Information Login End******************** */}
            </div>
        </div>
    );
};

export default SignUp;