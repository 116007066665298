import React, { useState, useEffect } from 'react';
import "./styleProfileInfo.css";
import Swal from 'sweetalert2';
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import ProfileImg from '../../assets/profile.png';
import LogoAGS from '../../assets/AGS_logo_derive.png';
import { NavLink, Link } from 'react-router-dom';
import { RiShoppingCartFill } from 'react-icons/ri';
import { GrContactInfo } from 'react-icons/gr';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer } from 'react-toastify';
import { apiSignUp, entete, notify } from '../../Components/API_URL/ApiUrl';
import jwtDecode from 'jwt-decode';

const ProfileInfo = () => {
    const [status, setStatus] = useState();
    let id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    //Data Users
    const [user, setUser] = useState([]);
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [adresse, setAdresse] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');

    const getDataById = async () => {
        let token = localStorage.getItem('token');
        const { data } = await apiSignUp.get(`/${id}`, {
            headers: {
                entete,
                Authorization: `Bearer ${token}`
            }
        })
        console.log(data);
        setUser(data.data)
        setNom(data.data.nom)
        setPrenom(data.data.prenom)
        setAdresse(data.data.adresse)
        setEmail(data.data.email)
        setTelephone(data.data.telephone)
        setType(data.data.type)
        setPassword(data.data.password)
    }

    // Update de user
    const updateUser = async (e) => {
        // alert(ids)
        e.preventDefault();
        let token = localStorage.getItem('token');
        
        await apiSignUp.put(`/${id}`, 
        { 
            nom,
            prenom,
            adresse,
            email,
            telephone,
            type,
            password
        },
        {
            headers: {
                entete,
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setNom("")
            setPrenom("")
            setAdresse("")
            setEmail("")
            setTelephone("")
            setPassword("")
            setType("")
            handleCloseupd();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
            }).then(function () {
                setUser([]);
                getDataById();
                setStatus(true);
            });
        })
    };

    //Modal Update User
    const [showupd, setShowupd] = useState(false);
    const handleCloseupd = () => setShowupd(false);
    const handleShowupd = () => {
        if (type === "superadmin") {
            notify('warning', 'Les informtions de l\'administrateur principale ne sont pas modifiable')
        } else {
            setShowupd(true);
        }
    }

    const logout = () => {
        if (token) {
            const decodedToken = jwtDecode(token);
            const tokenExpirationTime = decodedToken.exp * 1000; // Convertir en millisecondes
            const currentTime = new Date().getTime();

            if (currentTime >= tokenExpirationTime) {
                // Le token est expiré, rediriger vers la page de connexion
                localStorage.clear();
                window.location.href = "/auth";
            } else {
                // Le token n'est pas encore expiré, déconnecter normalement
                localStorage.clear();
                window.location.href = "/auth";
            }
        } else {
            // Aucun token, simplement déconnecter
            localStorage.clear();
            window.location.href = "/auth";
        }
    };

    useEffect(() => {
        getDataById();
    }, [id]);

    return (
        <div className='containerHome'>
            <div className='header'>
                <Head/>
                <Header/>
                <NavBar/>
            </div>
            
            <ToastContainer 
                position="top-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className='body' style={{paddingBottom: "20px"}}>
                <div className='containerProfile' style={{alignItems: "flex-start"}}>
                    <div className='profile'>
                        <div className='containerImgProfile'>
                            <div className='profileImg'>
                                <img src={ProfileImg} alt='profile' width='100%' height='100%'/>
                            </div>
                            <h2>Bonjour <span style={{fontWeight: "bold"}}>{nom} {prenom}</span></h2>
                            <h4>{email}</h4>
                            <Link onClick={logout} className='btnChangeAccount'>Changer ce compte</Link>
                        </div>
                        <div className='containerInfo'>
                            <NavLink to={'/commande'} style={{gap: "4px"}} className='info'>
                                <RiShoppingCartFill size={20}/>
                                <div style={{width: "90%"}}>
                                    <div>Mes commandes</div>
                                    <span>Suivre, annuler, retourner</span>
                                </div>
                            </NavLink>

                            <NavLink to={'/profileInfo'} className='info' style={{background: "#111439", color: "#FFFFFF", gap: "4px"}}>
                                <GrContactInfo size={20}/>
                                <div style={{width: "90%"}}>
                                    <div>Mes informations personnelles</div>
                                    <span>Email, mot de passe...</span>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className='detailProfile'>
                        <div className='bodyprofile' style={{width: "100%"}}>
                            <div className='information'>
                                Vos informations personnelles
                            </div>
                            <div className='containerPersonnel'>
                                <div className='containerIdentity'>
                                    <div className='identity'>
                                        <span style={{fontWeight: 'bold'}}>Nom</span>
                                        <span>{nom}</span>
                                    </div>
                                    <div className='identity'>
                                        <span style={{fontWeight: 'bold'}}>Prenom</span>
                                        <span>{prenom}</span>
                                    </div>
                                    <div className='identity'>
                                        <span style={{fontWeight: 'bold'}}>E-mail</span>
                                        <span>{email}</span>
                                    </div>
                                    <div className='identity'>
                                        <span style={{fontWeight: 'bold'}}>Adresse</span>
                                        <span>{adresse}</span>
                                    </div>
                                    <div className='identity'>
                                        <span style={{fontWeight: 'bold'}}>Tel</span>
                                        <span>{telephone}</span>
                                    </div>
                                    <div className='identity'>
                                        <span style={{fontWeight: 'bold'}}>Statut</span>
                                        <span>{type}</span>
                                    </div>
                                </div>
                                
                                <div className='containerIdentity'>
                                    <button onClick={() => handleShowupd()} id='updProfile'>Modifier</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Update */}
                <Modal show={showupd} onHide={handleCloseupd}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h1>Modifier un utilisateur</h1>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            onSubmit={updateUser}
                        >
                            <Form.Group className='mb-3' controlId="nom"
                                style={{width: "100%"}}
                            >
                                <div style={{marginBottom: "5px"}}>Nom</div>
                                <Form.Control
                                    value={nom}
                                    onChange={(e) => setNom(e.target.value)}
                                    name='nom'
                                    type="text"
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='mb-3' controlId="prenom"
                                style={{width: "100%"}}
                            >
                                <div style={{marginBottom: "5px"}}>Prenom</div>
                                <Form.Control
                                    value={prenom}
                                    onChange={(e) => setPrenom(e.target.value)}
                                    name='prenom'
                                    type="text"
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='mb-3' controlId="adresse"
                                style={{width: "100%"}}
                            >
                                <div style={{marginBottom: "5px"}}>Adresse</div>
                                <Form.Control
                                    value={adresse}
                                    onChange={(e) => setAdresse(e.target.value)}
                                    name='adresse'
                                    type="text"
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='mb-3' controlId="email"
                                style={{width: "100%"}}
                            >
                                <div style={{marginBottom: "5px"}}>Email</div>
                                <Form.Control
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    name='email'
                                    type="email"
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='mb-3' controlId="telephone"
                                style={{width: "100%"}}
                            >
                                <div style={{marginBottom: "5px"}}>Téléphone</div>
                                <Form.Control
                                    value={telephone}
                                    onChange={(e) => setTelephone(e.target.value)}
                                    name='telephone'
                                    type="number"
                                    min={0}
                                    required
                                />
                            </Form.Group>
                            <Modal.Footer className='w-100'>
                                <button variant="secondary" onClick={handleCloseupd} className='btnfooter' style={{backgroundColor: "gray"}}>
                                    Fermer
                                </button>
                                <button type="submit" variant="primary" className='btnfooter'>
                                    Modifier
                                </button>
                            </Modal.Footer>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>

            <div className='footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default ProfileInfo;