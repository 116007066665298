import React, { useState, useEffect } from 'react';
import "./styleCommande.css";
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import ProfileImg from '../../assets/profile.png';
import LogoAGS from '../../assets/AGS_logo_derive.png';
import { Badge } from 'rsuite';
import { NavLink, Link } from 'react-router-dom';
import { RiShoppingCartFill } from 'react-icons/ri';
import { GrContactInfo } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { entete, apiLivraison, notify, apiLivraisonUser, apiLivraisonUserV } from '../../Components/API_URL/ApiUrl';
import { ToastContainer } from 'react-toastify';
import { Tabs, Tab, Box, Pagination  } from '@mui/material';
import jwtDecode from 'jwt-decode';


const Commande = () => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    const nom = localStorage.getItem('nom');
    const prenom = localStorage.getItem('prenom');
    const id = localStorage.getItem('id');
    const [search, setSearch] = useState("");
    const [livraisonInfo, setLivraisonInfo] = useState([]);
    const [infolivraison, setInfoLivraison] = useState([]);
    const [infolivraisonValide, setInfoLivraisonValide] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);

    const [status, setStatus] = useState();
    const dispatch = useDispatch();
    const items = useSelector(state => state.Reducers.items);
    console.log("Basket1: " + items);
    console.log("Basket2: " + JSON.stringify(items, null, 2));
    let addedItems = [];
    addedItems = items || [];
    console.log('Length: ' + addedItems.length);
    const [quantities, setQuantities] = useState(Array(addedItems.length).fill(1));
    const total = addedItems.reduce((acc, item, index) => acc + item.prix * quantities[index], 0);

    // ----------------------------------------
    const [value, setValue] = useState('one');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // ----------------------------------------

    const handleSearchInput = (e) => {
        setSearch(e.target.value);
    };

    // Afficher tous les livraisons
    const allLivraison = async () => {
        try {
            const response = await apiLivraisonUser.get(`/${id}`, {
                headers: {
                    entete
                }
            });
            
            // Utilisez les données filtrées directement depuis le serveur
            const etats = response.data.livraison;
            setInfoLivraison(etats);
            console.log("etatsss:", etats);
            console.log(infolivraison);
            console.log("object:::" + infolivraison[0].userID + "==" + id + ' ggffact:: ' + infolivraison[0].factureID);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
            console.log(error);
        }
    };

    const allLivraisonValide = async () => {
        try {
            const response = await apiLivraisonUserV.get(`/${id}`, {
                headers: {
                    entete
                }
            });
            
            // Utilisez les données filtrées directement depuis le serveur
            const etats = response.data.livraison;
            setInfoLivraisonValide(etats);
            console.log("etatsss:", etats);
            console.log(infolivraison);
            console.log("object:::" + infolivraison[0].userID + "==" + id + ' ggffact:: ' + infolivraison[0].factureID);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
            console.log(error);
        }
    };

    // Validation de la livraison
    const validerLivraison = async (e) => {
        // alert(ids)
        e.preventDefault();
        console.log('object' + infolivraison.panierinfosupID )
        await apiLivraison.put(`/${infolivraison[0].factureID}`,
        {
            headers: {
                entete
            }
        }).then(res => {
            allLivraison();
            allLivraisonValide([]);
            notify('success', "Votre facture a été ajouté avec succès");
        }).catch((err) => {
            
            console.log(err);
            notify('warning', "Une erreur s'est produite. Réésayez plus tard.");
        })
    };

    const gradientStyle = {
        // background: 'linear-gradient(to right, #00b09b, #96c93d)',
        background: 'linear-gradient(to right, #ff4e50, #f9d423)',
        color: '#fff',
        padding: '2px 10px',
    };
    const gradientStyleSuccess = {
        background: 'linear-gradient(to right, #00b09b, #96c93d)',
        color: '#fff',
        padding: '2px 10px',
    };

    const logout = () => {
        if (token) {
            const decodedToken = jwtDecode(token);
            const tokenExpirationTime = decodedToken.exp * 1000; // Convertir en millisecondes
            const currentTime = new Date().getTime();

            if (currentTime >= tokenExpirationTime) {
                // Le token est expiré, rediriger vers la page de connexion
                localStorage.clear();
                window.location.href = "/auth";
            } else {
                // Le token n'est pas encore expiré, déconnecter normalement
                localStorage.clear();
                window.location.href = "/auth";
            }
        } else {
            // Aucun token, simplement déconnecter
            localStorage.clear();
            window.location.href = "/auth";
        }
    };

    useEffect(() => {
        allLivraison();
        allLivraisonValide();
    }, []);

    return (
        <div className='containerHome'>
            <div className='header'>
                <Head/>
                <Header
                    search = {search}
                    handleSearchInput = {handleSearchInput}
                />
                <NavBar/>
            </div>
            <ToastContainer 
                position="top-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className='body' style={{paddingBottom: "20px"}}>
                <div className='containerProfile' style={{alignItems: "flex-start"}}>
                    <div className='profile'>
                        <div className='containerImgProfile'>
                            <div className='profileImg'>
                                <img src={ProfileImg} alt='profile' width='100%' height='100%'/>
                            </div>
                            <h2>Bonjour <span style={{fontWeight: "bold"}}>{nom} {prenom}</span></h2>
                            <h4>{email}</h4>
                            <Link onClick={logout} className='btnChangeAccount'>Changer ce compte</Link>
                        </div>
                        <div className='containerInfo'>
                            <NavLink to={'/commande'} className='info' style={{background: "#111439", color: "#FFFFFF", gap: "4px"}}>
                                <RiShoppingCartFill size={20}/>
                                <div style={{width: "90%"}}>
                                    <div>Mes commandes</div>
                                    <span>Suivre, annuler, retourner</span>
                                </div>
                            </NavLink>

                            <NavLink to={'/profileInfo'} style={{gap: "4px"}}  className='info'>
                                <GrContactInfo size={20}/>
                                <div style={{width: "90%"}}>
                                    <div>Mes informations personnelles</div>
                                    <span>Email, mot de passe...</span>
                                </div>
                            </NavLink>
                        </div>
                    </div>

                    <div className='detailProfile'>
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                <Tab value="one" label="Liste de factures toujours en cours" />
                                <Tab value="two" label="Liste de factures Valider" />
                            </Tabs>
                        </Box>
                        { value === 'one' && (
                            <div className='bodyprofile' style={{width: "100%"}}>
                                {
                                    infolivraison.length !== 0 ?
                                    <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                        {
                                            infolivraison.filter((item) => {
                                                if (search === "") {
                                                    return item;
                                                } else if (item.Facture.ref_facture.toLowerCase().includes(search.toLowerCase())) {
                                                    return item;
                                                }
                                            }).map(item => (
                                                <div className='containerLivraison'>
                                                    <div style={{width: "100%", textAlign: "center"}}>
                                                        <h3>{item.Facture.ref_facture}</h3>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                                        <div style={{display: "flex", flexDirection: "column"}}>
                                                            <span>{item.Facture.nomcomplet}</span>
                                                            <span>{item.Facture.numero}</span>
                                                            <span>{item.Facture.email}</span>
                                                        </div>
                                                        <div style={{display: "flex", flexDirection: "column"}}>
                                                            <Badge
                                                                style={item.Facture.etat_fact === "En attente" ? gradientStyle : gradientStyleSuccess}
                                                                content={item.Facture.etat_fact === "En attente" ? "Facture non validée" : "Facture validée"}
                                                            />
                                                            <br/>
                                                            <Badge
                                                                style={item.etat_liv === "En attente" ? gradientStyle : gradientStyleSuccess}
                                                                content={item.etat_liv === "En attente" ? "Livraison non validée" : "Livraison validée"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{width: "100%", textAlign: "center", marginTop: "10px"}}>
                                                        <button onClick={validerLivraison} className='buttonLiv'>Valider La livraison</button>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div style={{width: "100%", textAlign: "center", marginBottom: "20px"}}>
                                        Pas d'article en attente de validation
                                    </div>
                                }
                                <Pagination 
                                    count={Math.ceil(infolivraison.length / rowsPerPage)} // Nombre total de pages
                                    page={page + 1} // Page en cours (+1 car le composant Pagination commence à partir de 1)
                                    onChange={(event, newPage) => setPage(newPage - 1)} // Gestionnaire de changement de page (-1 pour aligner avec la numérotation 0-based)
                                    color="primary"
                                    size="large"
                                />
                            </div>
                        )}


                        { value === 'two' && (
                            <div className='bodyprofile' style={{width: "100%"}}>
                                {
                                    infolivraisonValide.length !== 0 ?
                                    <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                        {
                                            infolivraisonValide.filter((item) => {
                                                if (search === "") {
                                                    return item;
                                                } else if (item.Facture.ref_facture.toLowerCase().includes(search.toLowerCase())) {
                                                    return item;
                                                }
                                            }).map(item => (
                                                <div className='containerLivraison'>
                                                    <div style={{width: "100%", textAlign: "center"}}>
                                                        <h3>{item.Facture.ref_facture}</h3>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                                        <div style={{display: "flex", flexDirection: "column"}}>
                                                            <span>{item.Facture.nomcomplet}</span>
                                                            <span>{item.Facture.numero}</span>
                                                            <span>{item.Facture.email}</span>
                                                        </div>
                                                        <div style={{display: "flex", flexDirection: "column"}}>
                                                            <Badge
                                                                style={item.Facture.etat_fact === "En attente" ? gradientStyle : gradientStyleSuccess}
                                                                content={item.Facture.etat_fact === "En attente" ? "Facture non validée" : "Facture validée"}
                                                            />
                                                            <br/>
                                                            <Badge
                                                                style={item.etat_liv === "En attente" ? gradientStyle : gradientStyleSuccess}
                                                                content={item.etat_liv === "En attente" ? "Livraison non validée" : "Livraison validée"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div style={{width: "100%", textAlign: "center", marginBottom: "20px"}}>
                                        Pas d'article displonible
                                    </div>
                                }
                                <Pagination 
                                    count={Math.ceil(infolivraisonValide.length / rowsPerPage)} // Nombre total de pages
                                    page={page + 1} // Page en cours (+1 car le composant Pagination commence à partir de 1)
                                    onChange={(event, newPage) => setPage(newPage - 1)} // Gestionnaire de changement de page (-1 pour aligner avec la numérotation 0-based)
                                    color="primary"
                                    size="large"
                                />
                            </div>
                        )}
                    </div>

                </div>
            </div>

            <div className='footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default Commande;