import React, { useState, useEffect } from 'react';
import './styleFooter.css'
import { NavLink, Link } from 'react-router-dom';
import Paiement from '../../assets/moyens de paiem.png';
import { apiCategorie, entete } from '../API_URL/ApiUrl';
import { FaFacebookSquare, FaInstagram, FaYoutube, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
    const [categorie, setCategorie] = useState([]);

    // Fonction pour récupérer les catégories
    const allcategorie = async () => {
        try {
            apiCategorie.get('/', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log("CATTTTT"+res.data.data)
                setCategorie(res.data.data);
            })
        } catch (error) { throw error; }
    };

    useEffect(() => {
        allcategorie();
    }, []);

    return (
        <div className='containerFooter'>
            <div className='footerTop'>
                <div className='footerLogo'>
                    <div id='logo'></div>
                    <ul className='footerLogo'>
                        <li>
                            <Link className='sousMenu'>Ouagadougou</Link>
                        </li>
                        <li>
                            <Link className='sousMenu'>Burkina Faso</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>Catégories populaire</h3>
                    {
                        categorie.filter( category =>[7, 8, 1].includes(category.id))
                        .map(item => (
                           <ul className='footerLogo'>
                                <li>
                                    <NavLink
                                        to={`/equipement/${item.id}`}
                                        className='sousMenu'
                                    >
                                        {item.nomcat}
                                    </NavLink>
                                </li>
                            </ul>
                        ))
                    }
                </div>
                <div>
                    <h3>Informations</h3>
                    <ul className='footerLogo'>
                        <li>
                            <Link to={"/apropos"} className='sousMenu'>A propos de nous</Link>
                        </li>
                        <li>
                            <Link className='sousMenu'>Nos magasins</Link>
                        </li>
                        <li>
                            <Link className='sousMenu'>Dernières nouveelles</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>Nous suivre</h3>
                    <ul className='footerLogo'>
                        <div style={{display: "flex", flexDirection: 'row' ,gap: "8px"}}>
                            <Link>
                                <FaFacebookSquare size={22} color='red' />
                            </Link>
                            <Link>
                                <FaInstagram size={22} color='red' />
                            </Link>
                            <Link>
                                <FaYoutube size={22} color='red' />
                            </Link>
                            <Link>
                                <FaLinkedin size={22} color='red' />
                            </Link>
                        </div>
                        <li>
                            <Link className='sousMenu'>Termes et conditions</Link>
                        </li>
                        <li>
                            <Link className='sousMenu'>Politiques de confidentialités</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='footerBottom'>
                <div>
                    <p>Africa Global Service &copy; 2023 Copyright. All Rights Reserved. Designed by <span style={{color: "red", cursor: "pointer"}}>jilmondeconsulting.com</span></p>
                </div>
                <div>
                    <img src={Paiement} alt='img'/>
                </div>
            </div>
        </div>
    );
};

export default Footer;