import { ADD_ITEM, REMOVE_ITEM, RESET_ITEMS } from "../ActionTypes";

const initialState = {
    items: [],
};


export const Reducers = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ITEM:
            return {
                ...state,
                items: [...state.items, action.item],
            };
        case REMOVE_ITEM:
            return {
                ...state,
                items: state.items.filter((item, index) => index !== action.index),
            };
        case RESET_ITEMS:
            return initialState;
        default: {
            return state
        }
    }
};