import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { apiArticle, entete, URL_Local } from "../API_URL/ApiUrl";
import { Link } from "react-router-dom";

const SlideCard = () => {
  const [article, setArticle] = useState([]);

  // Afficher tous les articles
  const allarticle = async () => {
    try {
        apiArticle.get('/', {
          headers: {
            entete
          }
        })
        .then(res => {
          console.log(res.data.data)
          setArticle(res.data.data);
        })
    } catch (error) { throw error; }
  };

  useEffect(() => {
    allarticle();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    appendDots: (dots) => {
      return <ul style={{ margin: "0px" }}>{dots}</ul>
    },
  }

  return (
    <>
      <Slider {...settings}>
        {article.map((value, index) => {
          return (
            <>
              <div className='box d_flex homeSlide' key={index}>
                <div className='left leftHomeSlide'>
                  <div style={{display: 'flex', gap: "5px", alignItems: "center"}}>
                    <span>
                      {value.nomarticle}
                    </span>
                  </div>
                  <Link to={`/materiel/detail/${value.id_art}`} className='aboutButton' >
                    Visiter les Collections
                  </Link>
                  <p>
                    {value.description}
                  </p>
                  <br/>
                </div>
                <div className='right'>
                  <img src={`${URL_Local}/image/${value.img_art}`} className="imgSlideHome" alt='' />
                </div>
              </div>
            </>
          )
        })}
      </Slider>
    </>
  )
}

export default SlideCard
