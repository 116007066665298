import React, { useState } from 'react';
import './stylePaiement.css';
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';

const Paiement = () => {
    const [search, setSearch] = useState("");

    const handleSearchInput = (e) => {
        setSearch(e.target.value);
    };

    return (
        <div className='containerMateriel'>
            <div className='header'>
                <Head/>
                <Header
                    search = {search}
                    handleSearchInput = {handleSearchInput}
                />
                <NavBar/>
            </div>

            <div>

            <div className="mainGrey">
                <div id="main" className="mainOP">
                    <div className="content ">
                        <div className="">
                            <div id="cds-card-payment-wo" className="opInit opInitPH opInitBig">
                                <div className="display-flex-centered"><div className="cds-col1">
                                <form>
                                    <div id="cds-form-title">
                                        <div className="cds-payment-form-title">
                                            <span className="title-text">Paiement sécurisé par carte </span>
                                            <div className="cds-icon" id="cds-CB"></div>
                                            <div className="cds-icon" id="cds-visa"></div>
                                            <div className="cds-icon" id="cds-mastercard"></div>
                                        </div>
                                    </div>
                                    <div className="bdShadow opInitCartes">
                                        <fieldset>
                                            <div className="fm">
                                                <div>
                                                    <div className="fmLine" style={{display: 'flex', flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                                                        <label className="no767" for="CardOwner">Titulaire de la carte&nbsp;:</label>
                                                        <div className="fmIpt ">
                                                            <input id="CardOwner" name="CardOwner" type="text" spellcheck="false" placeholder="Titulaire de la carte" className="txtCardOwner" autocomplete="off" maxlength="30" value="Polet Andre"/>
                                                            <span className="" style={{width: "clac(100% - 15px)", position: "absolute", top: "10px"}}></span>
                                                        </div>
                                                    </div>
                                                    <div className="fmLine" style={{display: 'flex', flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                                                        <label className="no767" for="CardNumber">Numéro de carte&nbsp;:</label>
                                                        <div className="fmIpt ">
                                                            <input id="CardNumber" name="CardNumber" placeholder="Numéro de carte" autocomplete="off" type="tel" className="txtCardNumber" spellcheck="false" value=""/>
                                                            <span className="" style={{width: "clac(100% - 15px)", position: "absolute", top: "10px"}}></span>
                                                        </div>
                                                    </div>
                                                    <div className="fmLine" style={{display: 'flex', flexDirection: "row", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap"}}>
                                                        <label className="no767" for="ddlMonth">Date d'expiration&nbsp;:</label>
                                                        <div className="fmIpt biSel">
                                                        <select id="ddlMonth" name="ddlMonth">
                                                            <option value="1">01</option>
                                                            <option value="2">02</option>
                                                            <option value="3">03</option>
                                                            <option value="4">04</option>
                                                            <option value="5">05</option>
                                                            <option value="6">06</option>
                                                            <option value="7">07</option>
                                                            <option value="8">08</option>
                                                            <option value="9">09</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                        </select>
                                                    </div>
                                                    <div className="fmIpt biSel">
                                                        <select id="ddlYear" name="ddlYear">
                                                            <option value="2023">2023</option>
                                                            <option value="2024">2024</option>
                                                            <option value="2025">2025</option>
                                                            <option value="2026">2026</option>
                                                            <option value="2027">2027</option>
                                                            <option value="2028">2028</option>
                                                            <option value="2029">2029</option>
                                                            <option value="2030">2030</option>
                                                            <option value="2031">2031</option>
                                                            <option value="2032">2032</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="fmLine">
                                                    <label className="no767" for="SecurityNumber">Cryptogramme visuel&nbsp;:</label>
                                                    <div className="fmIpt crypto">
                                                        <input autocomplete="off" className="" id="SecurityNumber" name="SecurityNumber" placeholder="Cryptogramme visuel" type="tel" spellcheck="false" value=""/>
                                                    </div>
                                                    <span className="tip inputTip" style={{padding: "0.7em 0px"}}>3 derniers chiffres au dos de votre carte, à droite de la signature</span>
                                                </div>
                                            </div>
                                            <div className="clear center" id="JsPayRecap">
                                                <div className="onePgSbt">
                                                    <div></div>
                                                    <input id="ValidateButton" type="submit" className="sbt btGreen" value="Payer la commande"/>
                                                </div>
                                            </div>
                                        </div>
                                        </fieldset>
                                    </div>
                                </form>
                                <div className="fm">
                                    <div className="chgP">
                                        <div></div>
                                    </div>
                                </div>
                                <div className="fix"></div>
                                </div>
                                <div className="cds-col2">
                                    <div className="cds-payment-form-title">
                                        <span className="title-text">Récapitulatif </span>
                                    </div>
                                    <div id="order-data"><div id="payment-recap-block">
                                    <div className="custom-table-row">
                                        <div className="row-name table-row-bold">Articles</div>
                                        <div className="row-content"></div>
                                    </div>
                                    <div id="products-recap">
                                        <div className="product-table-row">
                                            <div className="row-product-name">
                                                <span></span>Sous Total
                                            </div>
                                            <div className="row-product-value">279,00&nbsp;F CFA <sup></sup></div>
                                        </div>
                                        
                                    </div>
                                    <div className="custom-table-row">
                                        <div className="row-name table-row-bold">Livraison</div>
                                        <div className="row-content">0,00&nbsp;F CFA</div>
                                    </div>
                                    <div className="custom-table-row hidden">
                                        <div className="row-name table-row-bold">Remise</div>
                                        <div className="row-content">0,00&nbsp;F CFA</div>
                                    </div>
                                    <div className="custom-table-row hidden">
                                        <div className="row-name table-row-bold">Montant de la TVA</div>
                                        <div className="row-content">- 0,01&nbsp;F CFA</div>
                                    </div>
                                    <div className="custom-table-row hidden">
                                        <div className="row-name table-row-bold">Frais de gestion du dossier</div>
                                        <div className="row-content">0,00&nbsp;€</div>
                                    </div>
                                    <div className="custom-table-row">
                                        <div className="row-name table-row-bold">Total commande</div>
                                        <div className="row-content table-row-bold">279,00&nbsp;F CFA</div>
                                    </div>
                                </div>
                                <div id="sms-bottom-total-amount">
                                    <div className="bold-content">Total commande</div>
                                    <div>
                                        <a>Voir le récapitulatif</a>
                                    </div>
                                    <div className="bold-content sms-total-amount">279,00&nbsp;F CFA <sup></sup></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            </div>
            </div>
            
            </div>

            <div className='footer'>
                <Footer/>
            </div>
        </div>
        </div>
    );
};

export default Paiement;