import React, { useState, useEffect } from 'react';
import "./styleAccueil.css";
import { Link } from 'react-router-dom';
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Slides from '../../Components/Carousel/Slide';
import { Card1, Card2, Card3 } from '../../Components/Card/CardH';
import Footer from '../../Components/Footer/Footer';
import Logo from "../../assets/AGS_logo_derive.png";
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCard } from '../Panier/redux/action/Actions';
import { apiArticle, entete, apiArticleByCategorie, apiApropos, apiContenuImg, fCurrencyDot, URL_Local } from '../../Components/API_URL/ApiUrl';
import { FlashDeals, FlashDeals2 } from './FlashDeals/FlashDeals';
import Home from '../../Components/Carousel/Home';


const Accueil = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [article, setArticle] = useState([]);
    const [articleByCat, setArticleByCat] = useState([]);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");

    const [idC, setIdC] = useState(0);
    const [titleSectionImg, setTitleSectionImg] = useState("");
    const [descSectionImg, setDescSectionImg] = useState("");
    const [imgSectionImg, setImgSectionImg] = useState("");
    const [imgSectionImgupd, setImgSectionImgupd] = useState("");

    const [imgSectionImg2, setImgSectionImg2] = useState("");
    const [imgSectionImg2upd, setImgSectionImg2upd] = useState("");


    const [titleSectionImg3, setTitleSectionImg3] = useState("");
    const [descSectionImg3, setDescSectionImg3] = useState("");
    const [imgSectionImg3, setImgSectionImg3] = useState("");
    const [imgSectionImg3upd, setImgSectionImg3upd] = useState("");

    const [titleSectionImg4, setTitleSectionImg4] = useState("");
    const [descSectionImg4, setDescSectionImg4] = useState("");
    
    const [titleSectionImg5, setTitleSectionImg5] = useState("");
    const [descSectionImg5, setDescSectionImg5] = useState("");
    const [imgSectionImg5, setImgSectionImg5] = useState("");
    const [imgSectionImg5upd, setImgSectionImg5upd] = useState("");

    //****************************************** */
    const handleSearchInput = (e) => {
        setSearch(e.target.value);
    };
    // Dispatch
    const dispatch = useDispatch()

    const items = useSelector(state => state.Reducers.items);
    // console.log("KKK :" + (items ? items.length : 0));
    let addedItems = [];
    addedItems = items || [];
    console.log('Length: ' + addedItems.length);

    // Ajouter un article au panier
    const addItem = (item) => {
        // dispatch(addItemToCard(item));
        // const itemInCart = addedItems.find((cartItem) => cartItem.id_art === item.id_art);
        // Vérifier si l'article est déjà présent dans le panier
        const itemInCart = addedItems.find((cartItem) => cartItem.id_art === item.id_art);

        if (!itemInCart) {
            // Si l'article n'est pas déjà présent, alors l'ajouter au panier
            dispatch(addItemToCard(item));
        } else {
            // Sinon, afficher un message ou une notification indiquant que l'article est déjà dans le panier.
            console.log("Cet article est déjà dans le panier.");
        }
    }; 
    //****************************************** */
    // Afficher le contenu
    const allcontenuByImg1 = async () => {
        let token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/1', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log(res.data.data)
                setIdC(res.data.data.id)
                setTitleSectionImg(res.data.data.titleSectionImg)
                setDescSectionImg(res.data.data.descSectionImg)
                setImgSectionImg(res.data.data.imgSectionImg)
                setImgSectionImgupd(res.data.data.imgSectionImg)
            })
        } catch (error) { throw error; }
    };
    const allcontenuByImg2 = async () => {
        let token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/2', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log(res.data.data)
                setImgSectionImg2(res.data.data.imgSectionImg)
                setImgSectionImg2upd(res.data.data.imgSectionImg)
            })
        } catch (error) { throw error; }
    };
    const allcontenuByImg3 = async () => {
        let token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/3', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log(res.data.data)
                setTitleSectionImg3(res.data.data.titleSectionImg)
                setDescSectionImg3(res.data.data.descSectionImg)
                setImgSectionImg3(res.data.data.imgSectionImg)
                setImgSectionImg3upd(res.data.data.imgSectionImg)
            })
        } catch (error) { throw error; }
    };
    const allcontenuByImg4 = async () => {
        let token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/4', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log(res.data.data)
                setTitleSectionImg4(res.data.data.titleSectionImg)
                setDescSectionImg4(res.data.data.descSectionImg)
            })
        } catch (error) { throw error; }
    };
    const allcontenuByImg5 = async () => {
        let token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/5', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log(res.data.data)
                setTitleSectionImg5(res.data.data.titleSectionImg)
                setDescSectionImg5(res.data.data.descSectionImg)
                setImgSectionImg5(res.data.data.imgSectionImg)
                setImgSectionImg5upd(res.data.data.imgSectionImg)
            })
        } catch (error) { throw error; }
    };
    //****************************************** */
    // Afficher tous les articles
    const allarticle = async () => {
        let token = localStorage.getItem('token');
        try {
            apiArticle.get('/', {
                headers: {
                    entete
                }
            })
                .then(res => {
                    console.log(res.data.data)
                    setArticle(res.data.data);
                })
        } catch (error) { throw error; }
    };

    // Afficher tous les articles par categorie
    const allarticleByCategorie = async () => {
        try {
          const response = await apiArticleByCategorie.get(`/3`, {
            headers: {
              entete
            }
          });
          console.log(response.data.data);
          setArticleByCat(response.data.data);
        } catch (error) {
          throw error;
        }
    };          

    // Afficher le contenu de A propos
    const allapropos = async () => {
        let token = localStorage.getItem('token');
        try {
            apiApropos.get('/1', {
                headers: {
                    entete,
                }
            })
                .then(res => {
                    console.log(res.data.data)
                    setTitle(res.data.data.title);
                    setDescription(res.data.data.description);
                    setEmail(res.data.data.email);
                    setTel(res.data.data.tel);
                })
        } catch (error) { throw error; }
    };


    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        allarticle();
        allarticleByCategorie();
        allapropos();
    }, []);
    useEffect(() => {
        allcontenuByImg1();
    }, [1]);
    useEffect(() => {
        allcontenuByImg2();
    }, [2]);
    useEffect(() => {
        allcontenuByImg3();
    }, [3]);
    useEffect(() => {
        allcontenuByImg4();
    }, [4]);
    useEffect(() => {
        allcontenuByImg5();
    }, [5]);

    if (isLoading) {
        return (
            <div style={{width: "100%", height: "100vh"}}>
                <div className="center loaderpanel">
                    <div className="ring" style={{width: "200px", height: "200px"}}></div>
                </div>
            </div>
        );
    }


    return (
        <div className='containerHome'>
            <div className='header'>
                <Head/>
                <Header
                    search = {search}
                    handleSearchInput = {handleSearchInput}
                />
                <NavBar/>
            </div>
            <div className='sliderImg'>
                <Slides/>
            </div>
            <div className='body'>
                {/* -------------SECTION VENTE FLASH START------------- */}
                <FlashDeals 
                    productItems={article} 
                    addToCart={addItem} 
                />
                <br/><br/>
                {/* -------------SECTION VENTE FLASH START------------- */}
                
                {
                    imgSectionImgupd && imgSectionImg2upd && imgSectionImg3upd?
                    <div className='containerCardMeubleAcc'>
                        <Card1
                            images = {imgSectionImgupd? imgSectionImgupd : "produit.jpg"}
                            title = {titleSectionImg}
                            desc = {descSectionImg}
                        />
                        <Card2
                            images = {imgSectionImg2upd? imgSectionImg2upd : "produit.jpg"}
                        />
                        <Card3
                            images = {imgSectionImg3upd? imgSectionImg3upd : "produit.jpg"}
                            title = {titleSectionImg3}
                            desc = {descSectionImg3}
                        />
                    </div>
                    :
                    <h2 style={{textAlign: "center"}}></h2>
                }
                {/* <h2 style={{textAlign: "center"}}>Pas d'article disponible</h2> */}
                
                {/* -------------SECTION CATEGORIE START------------- */}
                <FlashDeals2
                    productItems={articleByCat} 
                />
                <br/>
                {/* -------------SECTION CATEGORIE START------------- */}
                
                {/* ------------------SLIDER START------------------*/}                    
                <Home />
                <br/>
                {/* ------------------SLIDER END--------------------*/}

                <div className='containerCardAbout'>
                    <div className='aboutLeft'>
                        <p className='aboutTitle'>{title}</p>
                        <p>
                            {description}
                        </p>
                        <br/>
                        <Link to={"/apropos"} className='aboutButton'>Lire plus de details</Link>
                    </div>
                    <div className='aboutRight'>
                        <div className='about'>
                            <p>Informations de contact</p>
                            <img src={Logo} alt='img' width="262px" height="112px"/>
                            <span>E-mail</span>
                            <p>{email}</p>
                            <span>Numéro téléphonique/Whatsapp</span>
                            <p>{tel}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default Accueil;