import { Routes, Route, BrowserRouter } from "react-router-dom";
import './App.css';
import { Provider } from "react-redux";
import { persistor } from "./store/store";
import Accueil from "./Screens/Accueil/Accueil";
import Error404 from "./Screens/Error/Error404";
import Contacte from "./Screens/Contacte/Contacte";
import Equipement from "./Screens/Equipement/Equipement";
import Favoris from "./Screens/Favoris/Favoris";
import Livraison from "./Screens/Livraison/Livraison";
import Materiel from "./Screens/Materiel/Materiel";
import Panier from "./Screens/Panier/Panier";
import Auth from "./Screens/Auth/Auth";
import SignUp from "./Screens/Auth/SignUp";
import Paiement from "./Screens/Paiement/Paiement";
import Detail from "./Screens/Detail/Detail";
import Commande from "./Screens/MesCommandes/Commande";
import ProfileInfo from "./Screens/ProfileInfo/ProfileInfo";
import Apropos from "./Screens/Apropos/Apropos";
import Profile from "./Screens/Profile/Profile";
import Facture from "./Screens/Facture/Facture";
import Maintenance from "./assets/Svg/Maintenance";

function App() {
  return (
    <Provider store={persistor}>
      <BrowserRouter>
        <Routes>
          <Route exact path="*" element={<Error404 />} />
          <Route exact path="/" element={<Accueil />} />
          <Route exact path="/auth" element={<Auth />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/contacte" element={<Contacte />} />
          <Route exact path="/equipement/:id" element={<Equipement />} />
          <Route exact path="/favoris" element={<Favoris />} />
          <Route exact path="/livraison" element={<Livraison />} />
          <Route exact path="/materiel/:id/:categoriId" element={<Materiel />} />
          <Route exact path="/panier" element={<Panier />} />
          <Route exact path="/paiement" element={<Paiement />} />
          <Route exact path="/materiel/detail/:id_art" element={<Detail />} />
          <Route exact path="/apropos" element={<Apropos />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/commande" element={<Commande />} />
          <Route exact path="/profileInfo" element={<ProfileInfo />} />
          <Route exact path="/facture" element={<Facture />} />
          {/*<Route exact path="/" element={<Maintenance />} />*/}
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
