import React from "react";
import { FlashCard, FlashCard2} from "../../../Components/FlashCard/FlashCard";
import "../styleAccueil.css";

export const FlashDeals = ({ productItems, addToCart }) => {
  return (
    <>
      <section className='flash'>
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h3>VENTE FLASH</h3>
          </div>
          <FlashCard productItems={productItems} addToCart={addToCart} />
        </div>
      </section>
    </>
  )
}

export const FlashDeals2 = ({ productItems }) => {
  return (
    <>
      <section className='flash'>
        <div className='container'>
          <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
            <i className='fa-solid fa-border-all' style={{color: "red", fontWeight: 'bolder', fontSize: "20px"}}></i>
            <span style={{fontWeight: "bolder", fontSize: "20px"}}>Top Categories</span>
          </div>
          <FlashCard2 productItems={productItems} />
        </div>
      </section>
    </>
  )
}
