import React, { useState, useEffect } from 'react';
import './styleNavbar.css'
import { NavLink} from 'react-router-dom';
import Menu from './Menu';
import { GiHamburgerMenu } from "react-icons/gi";
import { ReactDimmer } from "react-dimmer";
import { Dropdown } from 'rsuite';
import Accordion from 'react-bootstrap/Accordion';
import { apiCategorie, entete, apiSousCat } from '../API_URL/ApiUrl';


const NavBar = () => {
    const type = localStorage.getItem('type');
    const token = localStorage.getItem('token');
    const [isMenuOpen, setMenu] = useState(false);
    const [categorie, setCategorie] = useState([]);
    const [sousCat, setSousCat] = useState([]);

    const handleMenu = () => {
        setMenu((prevState) => !prevState);
    };

    let linkStyle = ({ isActive }) => {
        return(
            isActive? {
                background: "#111439",
                color: "#FFFFFF"
            }
            :undefined
        )
    }

    // Fonction pour récupérer les catégories
    const allcategorie = async () => {
        try {
            apiCategorie.get('/', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log("CATTTTT"+res.data.data)
                setCategorie(res.data.data);
            })
        } catch (error) { throw error; }
    };

    // Fonction pour récupérer les sous-catégories en fonction de la catégorie sélectionnée
    const allsouscategorie = async (categorieId) => {
        try {
            apiSousCat.get(`/`, {
                headers: {
                    entete
                }
            })
            .then(res => {
                setSousCat(res.data.data);
            })
        } catch (error) { throw error; }
    };

    useEffect(() => {
        allcategorie();
        allsouscategorie();
    }, []);

    return (
        <div>
            <div className='containerNavbar'>
                <Dropdown title="Toutes nos categories" className='dropdown navCategorie'>
                    {categorie.map(categorie => (
                        <NavLink to={`/equipement/${categorie.id}`} style={{textDecoration: 'none', color: '#000000', width: "100%" }}>
                            <Dropdown.Menu key={categorie.id} className='underMenu' eventKey={categorie.nomcat} title={categorie.nomcat}>
                                {/* Appeler la fonction handleCategorieSelect() avec l'ID de la catégorie sélectionnée */}
                                {sousCat.map(sousCategorie => (
                                    // Utiliser la condition pour n'afficher que les sous-catégories de la catégorie sélectionnée
                                    (sousCategorie.categoriId === categorie.id) &&
                                        <NavLink style={{textDecoration: 'none', color: '#000000', width: "100%"}} to={`/materiel/${sousCategorie.id}/${sousCategorie.categoriId}`}>
                                            <Dropdown.Item key={sousCategorie.id} eventKey={sousCategorie.libsouscat}>
                                                {sousCategorie.libsouscat}
                                            </Dropdown.Item>
                                        </NavLink>
                                    )
                                )}
                            </Dropdown.Menu>
                        </NavLink>
                    ))}
                </Dropdown>
                {
                    type ==="admin" || type ==="superadmin" && 
                    <NavLink 
                        to={`https://admin.africaglobaleservice.com`} 
                        className='navBar'
                        style={linkStyle}
                    >
                        Tableau de bord
                    </NavLink>

                }
                <NavLink 
                    to={`/`} 
                    className='navBar'
                    style={linkStyle}
                >
                    Accueil
                </NavLink>

                {
                    categorie.filter( category =>[7, 8, 1].includes(category.id))
                    .map(item => (
                       <NavLink 
                            to={`/equipement/${item.id}`} 
                            className='navBar'
                            style={linkStyle}
                        >
                            {item.nomcat}
                        </NavLink>
                    ))
                }
                {
                    token &&
                    <NavLink 
                        to={`/livraison`} 
                        className='navBar'
                        style={linkStyle}
                    >
                        Livraison
                    </NavLink>
                }
                {
                    token &&
                    <NavLink 
                        to={`/favoris`} 
                        className='navBar'
                        style={linkStyle}
                    >
                        Les favoris
                    </NavLink>
                }
                <NavLink 
                    to={`/contacte`} 
                    className='navBar'
                    style={linkStyle}
                >
                    Nous-contactez
                </NavLink>
            </div>

            <div className='containerhamburger'>
                <div 
                    className='navBar menu-btn'
                    onClick={handleMenu}
                >
                    <GiHamburgerMenu size={25} color='#FFFFFF'/>
                </div>
                <div className='categorieTablet'>
                    <Dropdown title="Toutes nos catégories" className='dropdown navCategorie'>
                        {categorie.map(categorie => (
                            <NavLink to={`/equipement/${categorie.id}`} style={{textDecoration: 'none', color: '#000000', width: "100%" }}>
                                <Dropdown.Menu key={categorie.id} className='underMenu' eventKey={categorie.nomcat} title={categorie.nomcat}>
                                {sousCat
                                    .filter(sousCategorie => sousCategorie.categoriId === categorie.id)
                                    .map(sousCategorie => (
                                        <NavLink style={{textDecoration: 'none', color: '#000000', width: "100%"}} to={`/materiel/${sousCategorie.id}/${sousCategorie.categoriId}`}>
                                            <Dropdown.Item key={sousCategorie.id} eventKey={sousCategorie.libsouscat}>
                                                {sousCategorie.libsouscat}
                                            </Dropdown.Item>
                                        </NavLink>
                                    ))}
                                </Dropdown.Menu>
                            </NavLink>
                        ))}
                    </Dropdown>
                </div>

                <div className='categorieMobile'>
                <Dropdown title="Toutes nos catégories" className='dropdown navCategorieMobile'>
                    <Accordion>
                        {categorie.map(categorie => (
                            <Accordion.Item key={categorie.id} eventKey={categorie.id.toString()}>
                                <Accordion.Header>{categorie.nomcat}</Accordion.Header>
                                <Accordion.Body style={{display: 'flex', flexDirection: 'column'}}>
                                    {sousCat
                                        .filter(sousCategorie => sousCategorie.categoriId === categorie.id)
                                        .map(sousCategorie => (
                                            <NavLink style={{textDecoration: 'none', color: '#000000', padding: '4px 0px'}} to={`/materiel/${sousCategorie.id}/${sousCategorie.categoriId}`}>
                                                {sousCategorie.libsouscat}
                                            </NavLink>
                                        ))
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Dropdown>
              
                </div>
                <Menu 
                    className='menuHamburger' 
                    isMenuOpen={isMenuOpen} 
                />

                <ReactDimmer
                    isOpen={isMenuOpen}
                    exitDimmer={setMenu}
                    zIndex={100}
                    blur={1.5}
                />
            </div>
        </div>
    );
};

export default NavBar;
