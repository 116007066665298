import React from 'react';
import './styleError.css'
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import Error from '../../assets/OBJECT.png'

const Error404 = () => {
    return (
        <div className='containerHome'>
            <div className='header'>
                <Head/>
                <Header/>
                <NavBar/>
            </div>
            <div className='bodyError'>
                <img src={Error} alt='error'/>
                <div className='error'>
                    <h1>404</h1>
                    <h1>PAGE NOT FOUND</h1>
                </div>
                
            </div>
            <div className='footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default Error404;