import React, { useState, useEffect } from 'react';
import './styleLivraison.css'
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import { Table, Pagination, Badge } from 'rsuite';
import Modal from 'react-bootstrap/Modal';
import { apiPanier, entete, notify, apiLivraison, apiGestionPaiementMenu, apiLivraisonUser, apiLivraisonUserV, fCurrencyDot } from '../../Components/API_URL/ApiUrl';
import { Link } from 'react-router-dom';
import Kkia from '../Paiement/Kkia';
import { ToastContainer } from 'react-toastify';
import { Tabs, Tab, Box, PaginationItem } from '@mui/material';


const { Column, HeaderCell, Cell } = Table;


const Livraison = () => {
    const id = localStorage.getItem('id');
    const [search, setSearch] = useState("");
    const [livraison, setLivraison] = useState([]);
    const [livraisonInfo, setLivraisonInfo] = useState([]);
    const [infolivraison, setInfoLivraison] = useState([]);
    const [infolivraisonValide, setInfoLivraisonValide] = useState([]);
    const [paiementDispo, setPaiementDispo] = useState([]);
    const [userID, setUserID] = useState("");
    const [panierInfoSupID, setPanierInfoSupID] = useState(null);
    const [option1, setOption1] = useState("");
    const [option2, setOption2] = useState("");
    const [etat, setEtat] = useState("");
    const [etat1, setEtat1] = useState("");
    const [etat2, setEtat2] = useState("");

    const handleSearchInput = (e) => {
        setSearch(e.target.value);
    };

    
    // ----------------------------------------
    const [value, setValue] = useState('one');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // ----------------------------------------

    // Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    //Modal Paiement
    const [showcat, setShowcat] = useState(false);
    const handleClosecat = () => setShowcat(false);
    const handleShowcat = () => setShowcat(true);


    // Afficher tous les catégories
  {/*  const allAchatByIdUser = async () => {
        console.log('iddd::' + id)
        try {
            const response = await apiPanier.get(`/${id}/En attente`, {
                headers: {
                    entete
                }
            });
            console.log(response.data.paniers[0].PanierInfoSup);
            setLivraison(response.data.paniers);
            setUserID(response.data.paniers[0].userID);
            setPanierInfoSupID(response.data.paniers[0].PanierInfoSupID);
            setLivraisonInfo(response.data.paniers[0].PanierInfoSup);
            setEtat(response.data.paniers[0]);
            console.log("user:::" + userID +"=="+ id )
        } catch (error) {
            throw error;
        }
    }; */}
    const allAchatByIdUser = async () => {
        console.log('iddd::' + id);
        try {
          const response = await apiPanier.get(`/${id}/En attente`, {
            headers: {
              entete
            }
          });
      
          if (response.data.paniers && response.data.paniers.length > 0) {
            const firstPanier = response.data.paniers[0];
            console.log(firstPanier.PanierInfoSup);
            setLivraison(response.data.paniers);
            setUserID(firstPanier.userID);
            setPanierInfoSupID(firstPanier.PanierInfoSupID);
            setLivraisonInfo(firstPanier.PanierInfoSup);
            setEtat(firstPanier);
            console.log("user:::" + userID + "==" + id);
          } else {
            // Traiter le cas où le tableau est vide en utilisant une autre requête
            const newResponse = await apiPanier.get(`/${id}`, {
              headers: {
                entete
              }
            });
      
            if (newResponse.data.paniers && newResponse.data.paniers.length > 0) {
              const firstPanier = newResponse.data.paniers[0];
              setLivraison([]);
              setUserID(firstPanier.userID);
              setPanierInfoSupID(firstPanier.PanierInfoSupID);
              setLivraisonInfo(firstPanier.PanierInfoSup);
              setEtat(firstPanier);
              console.log("user:::" + userID + "==" + id + "etat " + etat);
            } else {
              console.log("Le tableau est vide.");
              setUserID(null);
              setPanierInfoSupID(null);
              setEtat(null);
            }
          }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
            console.log(error);
        }
    };      

    // Afficher tous les livraisons
   {/* const allLivraison = async () => {
        try {
            const response = await apiLivraison.get(`/`, {
                headers: {
                    entete
                }
            });
        
            // Mapper et associer etat_fact et etat_liv ensemble
            const etats = response.data.livraison.filter(item => item.etat_liv === "En attente" && item.Facture.etat_fact === "Valider" && item.userID === item.Facture.userID).map(item => ({
                etat_liv: item.etat_liv,
                factureID: item.factureID,
                etat_fact: item.Facture.etat_fact,
                ref_facture: item.Facture.ref_facture,
                nomcomplet: item.Facture.nomcomplet,
                numero: item.Facture.numero,
                email: item.Facture.email,
                panierinfosupID: item.Facture.panierinfosupID,
                date: item.Facture.date,
                userID: item.Facture.userID,
            }));
            setInfoLivraison(etats);
            console.log("etatsss:" + etats);
            console.log(infolivraison);
            console.log("object:::" + infolivraison[0].userID +"=="+ id + ' ggffact:: '+ infolivraison[0].factureID )
    
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
            console.log(error);
        }
    }; */}
    const allLivraison = async () => {
        try {
            const response = await apiLivraisonUser.get(`/${id}`, {
                headers: {
                    entete
                }
            });
            
            // Utilisez les données filtrées directement depuis le serveur
            const etats = response.data.livraison;
            setInfoLivraison(etats);
            console.log("etatsss:", etats);
            console.log(infolivraison);
            console.log("object:::" + infolivraison[0].userID + "==" + id + ' ggffact:: ' + infolivraison[0].factureID);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
            console.log(error);
        }
    };

    const allLivraisonValide = async () => {
        try {
            const response = await apiLivraisonUserV.get(`/${id}`, {
                headers: {
                    entete
                }
            });
            
            // Utilisez les données filtrées directement depuis le serveur
            const etats = response.data.livraison;
            setInfoLivraisonValide(etats);
            console.log("etatsss:", etats);
            console.log(infolivraison);
            console.log("object:::" + infolivraison[0].userID + "==" + id + ' ggffact:: ' + infolivraison[0].factureID);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
            console.log(error);
        }
    };
    

    // Afficher tous les Paiements disponible
    const allPaiementMenu = async () => {
        let token = localStorage.getItem('token');
        try {
            const response = await apiGestionPaiementMenu.get('/', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
            // Mapper et associer etat_fact et etat_liv ensemble
            const menupaiement = response.data.data.filter(item => item.etat === "activer").map(item => ({
                option: item.option,
                etat: item.etat,
            }));
            setPaiementDispo(menupaiement);
            setOption1(menupaiement[0].option);
            setEtat1(menupaiement[0].etat);
            setOption2(menupaiement[1].option);
            setEtat2(menupaiement[1].etat);
            console.log(menupaiement[0].option);
            console.log("object:::" + option1 +"==="+ etat1 + ' ggffact:: '+ option2 + "==" + etat2 )
    
        } catch (error) { 
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
            console.log(error);
        }
    };
     
      

    //PARGINATION TABLEAU
    /*const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);*/

    //---------------------------
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(4);

    const handleChangeLimit = dataKey => {
        setPage(1);
        setLimit(dataKey);
    };

    const data = livraison.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
    });

    const gradientStyle = {
        // background: 'linear-gradient(to right, #00b09b, #96c93d)',
        background: 'linear-gradient(to right, #ff4e50, #f9d423)',
        color: '#fff',
        padding: '2px 10px',
    };
    const gradientStyleSuccess = {
        background: 'linear-gradient(to right, #00b09b, #96c93d)',
        color: '#fff',
        padding: '2px 10px',
    };

    // Validation de la livraison
    const validerLivraison = async (e) => {
        // alert(ids)
        e.preventDefault();
        console.log('object' + infolivraison.panierinfosupID )
        await apiLivraison.put(`/${infolivraison[0].factureID}`,
        {
            headers: {
                entete
            }
        }).then(res => {
            setLivraisonInfo([]);
            setInfoLivraison([]);
            notify('success', "Votre facture a été ajouté avec succès");
        }).catch((err) => {
            
            console.log(err);
            notify('warning', "Une erreur s'est produite. Réésayez plus tard.");
        })
    };

    useEffect(() => {
        allLivraison();
        allPaiementMenu();
        allAchatByIdUser();
        allLivraisonValide();
    }, []);

    return (
        <div className='containerMateriel'>
            <div className='header'>
                <Head/>
                <Header 
                    search = {search}
                    handleSearchInput = {handleSearchInput}
                />
                <NavBar/>
            </div>
            <ToastContainer 
                position="top-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {userID == id ?
                <div className='bodyAdmin' style={{width: "100%"}}>
                    <div className='sectionPanel'>
                        <div className='containerPanelFlash'>
                            <p className='panelFlash'>Liste des Articles avant validation de la facture</p>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Table virtualized data={data} autoHeight style={{marginBottom: "20px", width: "100%"}}>
                            <Column width={300} align="center">
                                <HeaderCell>Article</HeaderCell>
                                <Cell style={{display: "flex", justifyContent: "center", alignItems: "center" }} dataKey="nomarticle" />
                            </Column>

                            <Column width={300} align="center">
                                <HeaderCell>Prix</HeaderCell>
                                <Cell style={{display: "flex", justifyContent: "center", alignItems: "center" }} dataKey="prix" />
                            </Column>

                            <Column width={200} align="center">
                                <HeaderCell>Quantité</HeaderCell>
                                <Cell style={{display: "flex", justifyContent: "center", alignItems: "center" }} dataKey="qte" />
                            </Column>

                            <Column width={200} align="center">
                                <HeaderCell>Total</HeaderCell>
                                <Cell style={{display: "flex", justifyContent: "center", alignItems: "center" }} dataKey="totalByArcticle" />
                            </Column>

                            <Column width={200} align="center">
                                <HeaderCell>Status</HeaderCell>
                                <Cell style={{display: "flex", justifyContent: "center", alignItems: "center" }} dataKey="etat" />
                            </Column>
                        </Table>
                    </div>

                    <div style={{display: 'flex', justifyContent: "center", width: "100%"}}>
                        <div style={{ padding: "10px 20px", width: "70%" }}>
                            <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                maxButtons={5}
                                size="xs"
                                layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                                total={livraison.length}
                                limitOptions={[10, 30, 50]}
                                limit={limit}
                                activePage={page}
                                onChangePage={setPage}
                                onChangeLimit={handleChangeLimit}
                                style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "40px"}}
                            />
                        </div>
                    </div>

                    {livraison != 0 &&
                        <div className='containerGetTotal'>
                            {/* {etat.etat == "En attente" && userID == localStorage.getItem('id') && panierInfoSupID == livraisonInfo.id && (
                                <div className='containerAccount'>
                                    <div className='account'>
                                        <p className='labelAccount'>Expédition:</p>
                                        <p>{livraisonInfo.prixExpediton} F CFA</p>
                                    </div>
                                    <div className='account'>
                                        <p className='labelAccount'>Sous total:</p>
                                        <p>{livraisonInfo.preTotal} F CFA</p>
                                    </div>
                                    <div className='account'>
                                        <p>Code:</p>
                                        <p>{livraisonInfo.codePromo}</p>
                                    </div>
                                    <div className='account'>
                                        <p className='labelAccount'>Total:</p>
                                        <p style={{fontWeight: "bolder"}}>{livraisonInfo.prixTotal} F CFA</p>
                                    </div>
                                </div>
                            )}  */}                         


                            {etat.etat == "En attente" && userID == localStorage.getItem('id') && panierInfoSupID == livraisonInfo.id && (
                                <div className='containerAccount'>
                                    <div className='account'>
                                        <p>Pays</p>
                                        <p>{livraisonInfo.pays}</p>
                                    </div>
                                    <div className='account'>
                                        <p>Etat/Province</p>
                                        <p>{livraisonInfo.etatProvince}</p>
                                    </div>

                                    <div className='account'>
                                        <p>Adresse</p>
                                        <p>{livraisonInfo.Adresse}</p>
                                    </div>
                                    <div className='account'>
                                        <p>Code postal</p>
                                        <p>{livraisonInfo.codePostal}</p>
                                    </div>
                                    <div className='account'>
                                        <p className='labelAccount'>Total:</p>
                                        <p>{fCurrencyDot(livraisonInfo.preTotal)} F CFA</p>
                                    </div>
                                </div>
                            )}    
                        </div>
                    }
                    {livraison != 0 &&
                        <button onClick={handleShow} className='paiementBtn' style={{marginBottom: "20px"}}>
                            Valider le paiement
                        </button>
                    }
                    {/* <button type='submit' style={{marginBottom: "20px"}}>
                        <Link to={'/paiement'} className='paiementBtn'  style={{textDecoration: "none", width: "100%"}}>Valider le paiement</Link>
                </button> */}
                    {/*{livraison != 0 &&
                        <Kkia price = {livraisonInfo.prixTotal}/>
                    } */}
                </div>
                :
                <div className='bodyAdmin'>
                    <div className='sectionPanel'>
                        <div className='containerPanelFlash'>
                            <p className='panelFlash'>Liste des Articles avant validation de la facture</p>
                        </div>
                    </div>
                    <div style={{width: "100%", textAlign: "center", marginBottom: "20px", height: '100vh'}}>
                        Pas d'article en attente de validation
                    </div>
                </div>
            }

            <div className='bodyAdmin' style={{width: "100%"}}>
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="one" label="Liste des livraisons toujours en cours" />
                        <Tab value="two" label="Liste des livraisons Valider" />
                    </Tabs>
                </Box>
            </div>

            {/* ---------------------LISTE DES LIVRAISONS EN COURS START--------------------- */}
            { value === 'one' && (
                <div className='bodyAdmin' style={{width: "100%", marginBottom: "20px"}}>
                    {userID == id && (
                        <div style={{width: "100%"}}>
                            {
                                infolivraison.length !== 0 ?
                                <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                    {
                                        infolivraison.filter((item) => {
                                            if (search === "") {
                                                return item;
                                            } else if (item.Facture.ref_facture.toLowerCase().includes(search.toLowerCase())) {
                                                return item;
                                            }
                                        }).map(item => (
                                            <div className='containerLivraison'>
                                                <div style={{width: "100%", textAlign: "center"}}>
                                                    <h3>{item.Facture.ref_facture}</h3>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <span>{item.Facture.nomcomplet}</span>
                                                        <span>{item.Facture.numero}</span>
                                                        <span>{item.Facture.email}</span>
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <Badge
                                                            style={item.Facture.etat_fact === "En attente" ? gradientStyle : gradientStyleSuccess}
                                                            content={item.Facture.etat_fact === "En attente" ? "Facture non validée" : "Facture validée"}
                                                        />
                                                        <br/>
                                                        <Badge
                                                            style={item.etat_liv === "En attente" ? gradientStyle : gradientStyleSuccess}
                                                            content={item.etat_liv === "En attente" ? "Livraison non validée" : "Livraison validée"}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{width: "100%", textAlign: "center", marginTop: "10px"}}>
                                                    <button onClick={validerLivraison} className='buttonLiv'>Valider La livraison</button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                :
                                <div>Pas de livraison en cours</div>
                            }
                            <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                maxButtons={5}
                                size="xs"
                                layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                                total={infolivraison.length}
                                limitOptions={[10, 30, 50]}
                                limit={limit}
                                activePage={page}
                                onChangePage={setPage}
                                onChangeLimit={handleChangeLimit}
                                style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "40px"}}
                            />
                        </div>
                    )}
                </div>
            )}
            {/* ---------------------LISTE DES LIVRAISONS EN COURS END--------------------- */}

            {/* ---------------------LISTE DES LIVRAISONS VALIDEE START--------------------- */}
            { value === 'two' && (
                <div className='bodyAdmin' style={{width: "100%", marginBottom: "20px"}}>
                    {userID == id && (
                        <div style={{width: "100%"}}>
                            {
                                infolivraisonValide.length !== 0 ?
                                <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                    {
                                        infolivraisonValide.filter((item) => {
                                            if (search === "") {
                                                return item;
                                            } else if (item.Facture.ref_facture.toLowerCase().includes(search.toLowerCase())) {
                                                return item;
                                            }
                                        }).map(item => (
                                            <div className='containerLivraison'>
                                                <div style={{width: "100%", textAlign: "center"}}>
                                                    <h3>{item.Facture.ref_facture}</h3>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <span>{item.Facture.nomcomplet}</span>
                                                        <span>{item.Facture.numero}</span>
                                                        <span>{item.Facture.email}</span>
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <Badge
                                                            style={item.Facture.etat_fact === "En attente" ? gradientStyle : gradientStyleSuccess}
                                                            content={item.Facture.etat_fact === "En attente" ? "Facture non validée" : "Facture validée"}
                                                        />
                                                        <br/>
                                                        <Badge
                                                            style={item.etat_liv === "En attente" ? gradientStyle : gradientStyleSuccess}
                                                            content={item.etat_liv === "En attente" ? "Livraison non validée" : "Livraison validée"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                :
                                <div>Pas de livraison disponible</div>
                            }
                            <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                maxButtons={5}
                                size="xs"
                                layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                                total={infolivraisonValide.length}
                                limitOptions={[10, 30, 50]}
                                limit={limit}
                                activePage={page}
                                onChangePage={setPage}
                                onChangeLimit={handleChangeLimit}
                                style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "40px"}}
                            />
                        </div>
                    )}
                </div>
            )}
            {/* ---------------------LISTE DES LIVRAISONS VALIDEE END--------------------- */}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h1>Choisir un mode de paiement</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        livraison.length !== 0?
                        <div style={{display: "flex", flexDirection: 'column', justifyContent: "flex-start", gap: "12px", width: "100%"}}>
                            {
                                option1 === "Paiement en espèce" && etat1 === "activer" ?
                                <button>
                                    <Link to={'/facture'} className='paiementBtn'  style={{textDecoration: "none", width: "100%"}}>
                                        Paiement en espèce
                                    </Link>
                                </button>
                                :
                                <div className='paiementBtn'>
                                    Le Paiement par Mobile money/Carte Bancaire est actuellement indisponible pour des raisons de maintenance
                                </div>
                            }
    
                            {
                                option2 === "Paiement par Mobile money/Carte Bancaire" && etat2 === "activer" ?
                                <Kkia price = {livraisonInfo.preTotal}/>
                                :
                                <div className='paiementBtn'>
                                    Le Paiement en espèce est actuellement indisponible pour des raisons de maintenance
                                </div>
                            }
                        </div>
                        :
                        <div>Pas disponible</div>
                    }
                </Modal.Body>
            </Modal>

            {/* <Modal 
                show={showcat} 
                onHide={handleClosecat}
                centered
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                className="custom-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h1>Paiement</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <iframe
                            src='https://sandbox-me.fedapay.com/LYywZiTb'
                            title='Modal de paiement'
                            style={{width: "100%", height: "50vh"}}
                        />
                </Modal.Body>
        </Modal> */}

            <div className='footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default Livraison;