import React, { useState, useEffect } from 'react';
import './styleEquipement.css';
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import Meuble from "../../assets/elise-bureau-reglable-hauteur-droit-bois.png"
import { ImPriceTag } from 'react-icons/im'
import MaterielEq from '../../assets/bannerequip.png'
import Mobilier from '../../assets/Data/Mobilier.json'
import { CardMeuble } from '../../Components/Card/CardH';
import { apiArticleByCategorie, entete } from '../../Components/API_URL/ApiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCard } from '../Panier/redux/action/Actions';
import { useParams } from 'react-router-dom';
import { CardItem } from '../../Components/FlashCard/FlashCard';


const Equipement = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState();
	const { id } = useParams();

    const handleSearchInput = (e) => {
        setSearch(e.target.value);
    };

    // Dispatch
    const dispatch = useDispatch()

    const items = useSelector(state => state.Reducers.items);
    // console.log("KKK :" + (items ? items.length : 0));
    let addedItems = [];
    addedItems = items || [];
    console.log('Length: ' + addedItems.length);

    // Basket
    const addItem = (item) => {
        // dispatch(addItemToCard(item));
        // const itemInCart = addedItems.find((cartItem) => cartItem.id_art === item.id_art);
        // Vérifier si l'article est déjà présent dans le panier
        const itemInCart = addedItems.find((cartItem) => cartItem.id_art === item.id_art);

        if (!itemInCart) {
            // Si l'article n'est pas déjà présent, alors l'ajouter au panier
            dispatch(addItemToCard(item));
        } else {
            // Sinon, afficher un message ou une notification indiquant que l'article est déjà dans le panier.
            console.log("Cet article est déjà dans le panier.");
        }
    }; 

    const [article, setArticle] = useState([]);

    // Afficher tous les articles par categorie
    const allarticle = async () => {
        try {
            await apiArticleByCategorie.get(`/${id}`, {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log(res.data.data)
                setArticle(res.data.data);
            })
        } catch (error) { 
            if (error.response && error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.error("Ressource introuvable (erreur 404)");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.error("Une erreur s'est produite lors de la récupération des articles.");
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        allarticle();
    }, [id]);

    if (isLoading) {
        return (
            <div style={{width: "100%", height: "100vh"}}>
                <div class="center loaderpanel">
                    <div class="ring" style={{width: "200px", height: "200px"}}></div>
                </div>
            </div>
        );
    }

    return (
        <div className='containerMateriel'>
            <div className='header'>
                <Head/>
                <Header
                    search = {search}
                    handleSearchInput = {handleSearchInput}
                />
                <NavBar/>
            </div>
            <div className='containerBodyPanier'>
                <img src={MaterielEq} alt='img' width="100%"/>
            </div>
            <div className='containerBodyPanier'>
                <h1 className='mobilierTitle'>
                    Nos récents succès
                    <div className='underline'></div>
                </h1>
                <p style={{fontSize: "20px", marginBottom: "20px", textAlign: "justify"}}>
                    Vous verrez une galerie d'équipements et 
                    mobilier de bureau dont nous disposons et 
                    ceux vendus ainsi que ceux réservés.
                </p>

               {/* {
                    article.length !==0 ?
                        <div className='equipementView'>
                            {   article.filter((item) => {
                                    if (search === "") {
                                        return item;
                                    } else if (item.statut.toLowerCase().includes(search.toLowerCase())) {
                                        return item;
                                    }
                                })
                                .map((item) => { 
                                    // Vérifie si l'article est déjà présent dans le panier
                                    const itemInCart = addedItems.find((cartItem) => cartItem.id_art === item.id_art);
                                    return (
                                        <CardMeuble
                                            idArt = {item.id_art}
                                            id = {item.id_art}
                                            nom = {item.nomarticle}
                                            images = {item.img_art}
                                            prix = {item.prix}
                                            statut = {item.etat}
                                            addpanier={() => !itemInCart && addItem(item)}
                                            nompanier = {itemInCart ? "Article ajouter" : "Ajouter au panier"}
                                            class = {'btnAddPanier'}
                                        />
                                    )
                            })
                            }
                        </div>
                        :
                        <div style={{width: "100%", textAlign: "center"}}>
                            <h2>Pas d'article disponible</h2>
                        </div>
                } */}
                {/* -------------SECTION CARD ARTICLE START------------- */}
                <div className='equipementView'>
                    <CardItem 
                        productItems={article} 
                        addToCart={addItem} 
                        search={search}
                    />
                </div>
                <br/><br/>
                {/* -------------SECTION CARD ARTICLE START------------- */}
            </div>

            <div className='footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default Equipement;