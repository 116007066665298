import React, { useState, useEffect} from 'react';
import "./styleHead.css"
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import 'moment-timezone';
import jwtDecode from 'jwt-decode';


const Head = () => {
    const token = localStorage.getItem('token');
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => setTime(new Date()), 1000);
        return () => clearInterval(timer);
    }, []);

    const hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    const seconds = time.getSeconds().toString().padStart(2, '0');
    const dateDays = time.toLocaleDateString();

    const logout = () => {
        if (token) {
            const decodedToken = jwtDecode(token);
            const tokenExpirationTime = decodedToken.exp * 1000; // Convertir en millisecondes
            const currentTime = new Date().getTime();

            if (currentTime >= tokenExpirationTime) {
                // Le token est expiré, rediriger vers la page de connexion
                localStorage.clear();
                window.location.href = "/auth";
            } else {
                // Le token n'est pas encore expiré, déconnecter normalement
                localStorage.clear();
                window.location.href = "/auth";
            }
        } else {
            // Aucun token, simplement déconnecter
            localStorage.clear();
            window.location.href = "/auth";
        }
    };

    return (
        <div>
            <div className="head">
                <span>Bienvenue chez Africa Globale Service</span>
                <span style={{fontWeight: "bold"}}>
                    {`${dateDays}`} &nbsp; &nbsp;
                    {`${hours}:${minutes}:${seconds}`}
                </span>
                <div className="compte">
                    {
                        token?
                        <Dropdown>
                            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                                Mon compte
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <Link to={'/profile'} style={{textDecoration: "none", color: "black"}}>Profile</Link>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={logout}>Déconnexion</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        :
                        <div></div>
                        
                    }
                    {/*
                        <Dropdown title="Mon compte" className='dropdown'>
                            <Dropdown.Item>
                                <Link to={'/auth'} style={{textDecoration: "none", color: "#FFFFFF"}}>Se connecter</Link>
                            </Dropdown.Item>
                        </Dropdown>
                    */}
                    <Link to={"/apropos"} className='navHead'>A propos de nous</Link>
                </div>
            </div>
        </div>
    );
};

export default Head;