import React, { useState, useEffect } from 'react';
import "./styleHeader.css"
import { FaSearch, FaUserAlt, FaShoppingCart } from "react-icons/fa"
import { Link } from 'react-router-dom';
import { Badge } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import { apiSignUp, entete } from '../API_URL/ApiUrl';


const Header = (props) => {
    let token = localStorage.getItem('token');
    let id = localStorage.getItem('id');
    // Dispatch
    const dispatch = useDispatch()

    const items = useSelector(state => state.Reducers.items);
    // console.log("KKK :" + (items ? items.length : 0));
    let addedItems = [];
    addedItems = items || [];
    console.log('Length: ' + addedItems.length);

    //Data Users
    const [user, setUser] = useState([]);
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [adresse, setAdresse] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');

    const getDataById = async () => {
        try {
            let token = localStorage.getItem('token');
            const { data } = await apiSignUp.get(`/${id}`, {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(data);
            setUser(data.data);
            setNom(data.data.nom);
            setPrenom(data.data.prenom);
            setAdresse(data.data.adresse);
            setEmail(data.data.email);
            setTelephone(data.data.telephone);
            setType(data.data.type);
            setPassword(data.data.password);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Gérer l'erreur 401 ici, par exemple, vous pouvez afficher un message à l'utilisateur
                console.log("Accès non autorisé. Veuillez vous connecter.");
            } else {
                // Gérer d'autres erreurs qui ne sont pas 401 ici
                console.error("Une erreur s'est produite lors de la récupération des données.");
            }
        }
    };    

    useEffect(() => {
        getDataById();
    }, [id]);

    return (
        <div>
            <div className='headers'>
                <Link to={"/"} id='logo'></Link>
                <div className='search'>
                    <input type='search'  value={props.search} onChange={props.handleSearchInput} placeholder='Recherchez un produit de votre choix' className='inputSaerch'/>
                    <button className='buttonSearch'>Rechercher</button>
                    <div className='iconSearch'>
                        <FaSearch size={25} color="#FFFFFF" />
                    </div>
                </div>
                <div className='panelAuth'>
                    {
                        token?
                        <Link to={"/profile"} className='containerPanel panelTablet'>
                          <div className='containerIcon'>
                                <FaUserAlt size={20} color='red'/>
                            </div>
                            <div className='containerText'>
                                <span>{nom} {prenom}</span>
                                <span>{email}</span>
                            </div>
                        </Link>
                        :
                        <Link to={"/auth"} className='containerPanel panelTablet'>
                            <div className='containerIcon'>
                                <FaUserAlt size={20} color='red'/>
                            </div>
                            <div className='containerText'>
                                <span>Se connecter</span>
                                <span>Mon compte</span>
                            </div>
                        </Link>
                    }
                    <Badge content={addedItems.length}>
                        <Link to={"/panier"} className='containerPanel panier'>
                            <div className='containerIcon'>
                                <FaShoppingCart size={20} color='red'/>
                            </div>
                        </Link>
                    </Badge>
                </div>

                <div className='containerSearchMobile'>
                    <div className='searchMobile'>
                        <input type='search'  value={props.search} onChange={props.handleSearchInput} placeholder='Recherchez un produit de votre choix' className='inputSaerchMobile'/>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default Header;




/*

 <div className='containerSearchTablet'>
    <div className='searchTablet'>
        <input type='search'  value={props.search} onChange={props.handleSearchInput} placeholder='Recherchez un produit de votre choix' className='inputSaerch'/>
        <div className='iconSearch'>
            <FaSearch size={25} color="#FFFFFF" />
        </div>
    </div>
</div>
-------------------------


<div className='containerText'>
    <p>Mon panier</p>
    <p>0 Articles</p>
</div>


*/