import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './styleProfile.css';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

// Components
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import { CardH, Card1, Card2, Card3 } from '../../Components/Card/CardH';
import { apiArticle, entete, apiApropos, apiContenuImg, notify } from '../../Components/API_URL/ApiUrl';
import { FlashDeals } from '../Accueil/FlashDeals/FlashDeals';
import { addItemToCard } from '../Panier/redux/action/Actions';

// Icones & Images
import { RiShoppingCartFill } from 'react-icons/ri';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { GrContactInfo} from 'react-icons/gr';
import LogoAGS from '../../assets/AGS_logo_derive.png';
import ProfileImg from '../../assets/profile.png';



const Profile = () => {
    const slider = useRef(null);
    const slider2 = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [article, setArticle] = useState([]);
    const email = localStorage.getItem('email');
    const nom = localStorage.getItem('nom');
    const prenom = localStorage.getItem('prenom');
    const token = localStorage.getItem('token');

    const [description, setDescription] = useState("");

    //POUR LE CONTENU IMAGES
    const [idC, setIdC] = useState(0);
    const [titleSectionImg, setTitleSectionImg] = useState("");
    const [descSectionImg, setDescSectionImg] = useState("");
    const [imgSectionImg, setImgSectionImg] = useState("");
    const [imgSectionImgupd, setImgSectionImgupd] = useState("");

    const [imgSectionImg2, setImgSectionImg2] = useState("");
    const [imgSectionImg2upd, setImgSectionImg2upd] = useState("");


    const [titleSectionImg3, setTitleSectionImg3] = useState("");
    const [descSectionImg3, setDescSectionImg3] = useState("");
    const [imgSectionImg3, setImgSectionImg3] = useState("");
    const [imgSectionImg3upd, setImgSectionImg3upd] = useState("");

    const [titleSectionImg4, setTitleSectionImg4] = useState("");
    const [descSectionImg4, setDescSectionImg4] = useState("");
    
    const [titleSectionImg5, setTitleSectionImg5] = useState("");
    const [descSectionImg5, setDescSectionImg5] = useState("");
    const [imgSectionImg5, setImgSectionImg5] = useState("");
    const [imgSectionImg5upd, setImgSectionImg5upd] = useState("");

    // Dispatch
    const dispatch = useDispatch()

    const items = useSelector(state => state.Reducers.items);
    // console.log("KKK :" + (items ? items.length : 0));
    let addedItems = [];
    addedItems = items || [];
    console.log('Length: ' + addedItems.length);

    // Ajouter un article au panier
    const addItem = (item) => {
        // dispatch(addItemToCard(item));
        // const itemInCart = addedItems.find((cartItem) => cartItem.id_art === item.id_art);
        // Vérifier si l'article est déjà présent dans le panier
        const itemInCart = addedItems.find((cartItem) => cartItem.id_art === item.id_art);

        if (!itemInCart) {
            // Si l'article n'est pas déjà présent, alors l'ajouter au panier
            dispatch(addItemToCard(item));
        } else {
            // Sinon, afficher un message ou une notification indiquant que l'article est déjà dans le panier.
            console.log("Cet article est déjà dans le panier.");
        }
    };

    // Afficher tous les articles
    const allarticle = async () => {
        let token = localStorage.getItem('token');
        try {
            apiArticle.get('/', {
                headers: {
                    entete
                }
            })
                .then(res => {
                    console.log(res.data.data)
                    setArticle(res.data.data);
                })
        } catch (error) { throw error; }
    };

    // Afficher le contenu de A propos
    const allapropos = async () => {
        let token = localStorage.getItem('token');
        try {
            apiApropos.get('/1', {
                headers: {
                    entete
                }
            })
                .then(res => {
                    console.log(res.data.data)
                    setDescription(res.data.data.description);
                })
        } catch (error) { throw error; }
    };

    //****************************************** */
    // Afficher le contenu
    const allcontenuByImg1 = async () => {
        let token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/1', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log(res.data.data)
                setIdC(res.data.data.id)
                setTitleSectionImg(res.data.data.titleSectionImg)
                setDescSectionImg(res.data.data.descSectionImg)
                setImgSectionImg(res.data.data.imgSectionImg)
                setImgSectionImgupd(res.data.data.imgSectionImg)
            })
        } catch (error) { throw error; }
    };
    const allcontenuByImg2 = async () => {
        let token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/2', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log(res.data.data)
                setImgSectionImg2(res.data.data.imgSectionImg)
                setImgSectionImg2upd(res.data.data.imgSectionImg)
            })
        } catch (error) { throw error; }
    };
    const allcontenuByImg3 = async () => {
        let token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/3', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log(res.data.data)
                setTitleSectionImg3(res.data.data.titleSectionImg)
                setDescSectionImg3(res.data.data.descSectionImg)
                setImgSectionImg3(res.data.data.imgSectionImg)
                setImgSectionImg3upd(res.data.data.imgSectionImg)
            })
        } catch (error) { throw error; }
    };
    const allcontenuByImg4 = async () => {
        let token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/4', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log(res.data.data)
                setTitleSectionImg4(res.data.data.titleSectionImg)
                setDescSectionImg4(res.data.data.descSectionImg)
            })
        } catch (error) { throw error; }
    };
    const allcontenuByImg5 = async () => {
        let token = localStorage.getItem('token');
        try {
            apiContenuImg.get('/5', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log(res.data.data)
                setTitleSectionImg5(res.data.data.titleSectionImg)
                setDescSectionImg5(res.data.data.descSectionImg)
                setImgSectionImg5(res.data.data.imgSectionImg)
                setImgSectionImg5upd(res.data.data.imgSectionImg)
            })
        } catch (error) { throw error; }
    };
    //****************************************** */

    const handleClickPrevious = () => {
        slider.current.slickPrev();
    };

    const handleClickNext = () => {
        slider.current.slickNext();
    };

    const handleClickPrevious2 = () => {
        slider2.current.slickPrev();
    };

    const handleClickNext2 = () => {
        slider2.current.slickNext();
    };

    const logout = () => {
        if (token) {
            const decodedToken = jwtDecode(token);
            const tokenExpirationTime = decodedToken.exp * 1000; // Convertir en millisecondes
            const currentTime = new Date().getTime();

            if (currentTime >= tokenExpirationTime) {
                // Le token est expiré, rediriger vers la page de connexion
                localStorage.clear();
                window.location.href = "/auth";
            } else {
                // Le token n'est pas encore expiré, déconnecter normalement
                localStorage.clear();
                window.location.href = "/auth";
            }
        } else {
            // Aucun token, simplement déconnecter
            localStorage.clear();
            window.location.href = "/auth";
        }
    };

    const settings = {
        dots: false,
        speed: 500,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 120, // mobile breakpoint
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768, // tablet breakpoint
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // *****************************************

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        allarticle();
        allapropos();
        // notify('success', `${nom} ${prenom} soit le bienvenu sur notre plateforme🥳🎉`) 
    }, []);
    useEffect(() => {
        allcontenuByImg1();
    }, [1]);
    useEffect(() => {
        allcontenuByImg2();
    }, [2]);
    useEffect(() => {
        allcontenuByImg3();
    }, [3]);
    useEffect(() => {
        allcontenuByImg4();
    }, [4]);
    useEffect(() => {
        allcontenuByImg5();
    }, [5]);

    if (isLoading) {
        return (
            <div style={{width: "100%", height: "100vh"}}>
                <div class="center loaderpanel">
                    <div class="ring" style={{width: "200px", height: "200px"}}></div>
                </div>
            </div>
        );
    }

    return (
        <div className='containerHome'>
            <ToastContainer 
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
            <div className='header'>
                <Head/>
                <Header/>
                <NavBar/>
            </div>

            <div className='body'>
                <div className='containerProfile'>
                    <div className='profile'>
                        <div className='containerImgProfile'>
                            <div className='profileImg'>
                                <img src={ProfileImg} alt='profile' width='100%' height='100%'/>
                            </div>
                            <h2>Bonjour <span style={{fontWeight: "bold"}}>{nom} {prenom}</span></h2>
                            <h4>{email}</h4>
                            <Link onClick={logout} className='btnChangeAccount'>Changer ce compte</Link>
                        </div>
                        <div className='containerInfo'>
                            <NavLink to={'/commande'} style={{gap: "4px"}} className='info'>
                                <RiShoppingCartFill size={20}/>
                                <div style={{width: "90%"}}>
                                    <div>Mes commandes</div>
                                    <span>Suivre, annuler, retourner</span>
                                </div>
                            </NavLink>

                            <NavLink to={'/profileInfo'} style={{gap: "4px"}} className='info'>
                                <GrContactInfo size={20}/>
                                <div style={{width: "90%"}}>
                                    <div>Mes informations personnelles</div>
                                    <span>Email, mot de passe...</span>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className='detailProfile'>
                        <div style={{textAlign: "center", width: "100%", fontWeight: "bolder", fontSize: "6rem"}}>Espace client</div>
                        <div className='bodyprofile' style={{fontSize: "14px"}}>
                            {description}
                        </div>
                        <h4 style={{textAlign: "justify"}}>
                            Avec <span>AGS</span>, vous pouvez offrir une expérience d'achat 
                            pratique, sécurisée et personnalisée.
                        </h4>
                        <div className='footerProfile'>
                            <div className='imgLogoContainer'>
                                <img src={LogoAGS} alt='logo' width="100%" height="100%"/>
                            </div>
                            <div>
                                <ul>
                                    <li>Livraison rapide et sécurisée</li>
                                    <li>Des <span>cadeau disponibles</span>après plusieurs achats</li>
                                </ul>
                            </div>
                            <div>
                                <NavLink to={'/'} style={{textDecoration: "none"}} className='btnDetailProfile'>Profitez-en !</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                {/* -------------SECTION VENTE FLASH START------------- */}
                <FlashDeals 
                    productItems={article} 
                    addToCart={addItem} 
                />
                <br/><br/>
                {/* -------------SECTION VENTE FLASH START------------- */}

                <div className='containerCardMeubleAcc'>
                    <Card1
                        images = {imgSectionImgupd? imgSectionImgupd : "produit.jpg"}
                        title = {titleSectionImg}
                        desc = {descSectionImg}
                    />
                    <Card2
                        images = {imgSectionImg2upd? imgSectionImg2upd : "produit.jpg"}
                    />
                    <Card3
                        images = {imgSectionImg3upd? imgSectionImg3upd : "produit.jpg"}
                        title = {titleSectionImg3}
                        desc = {descSectionImg3}
                    />
                </div>
            </div>

            <div className='footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default Profile;