import React, { useState, useEffect } from 'react';
import './styleSlide.css';
import { Carousel } from 'rsuite';
import Banner from '../../assets/Slide/banner.png';
import Banner3 from '../../assets/Slide/bannerM.png';
import Banner6 from '../../assets/Slide/materiel.jpg';
import { apiContenuSlide, entetes, } from '../API_URL/ApiUrl';
import { URL_Local, URL } from '../API_URL/ApiUrl';

const Slides = () => {
    const [slide, setSlide] = useState([]);

    // Afficher tous les slides
    const allslide = async () => {
        let token = localStorage.getItem('token');
        try {
            apiContenuSlide.get('/', {
                headers: {
                    entetes
                }
            })
                .then(res => {
                    console.log(res.data.data)
                    setSlide(res.data.data);
                })
        } catch (error) { 
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
        }
    };

    useEffect(() => {
        allslide();
    }, []);

    return (
        <div>
            {
                slide.length !== 0 ? (
                    <Carousel autoplay className="custom-slider">
                        {
                            slide.map((item) => (
                                <img src={`${URL_Local}/image/slide/` + item.img_slide } className = "slideImg" />
                            ))
                        }
                    </Carousel>
                ) : (
                    <div className="custom-slider" style={{width: "100%"}}>
                        {/* <h3 style={{fontSize: "20px", textAlign: "center"}}>Pas de slide</h3> */}
                        <h3 style={{fontSize: "20px", textAlign: "center"}}></h3>
                    </div>
                )
            }
        </div>
    );
};

export default Slides;