import React from 'react';
import "./stylesCardH.css"
import { FaAngleRight } from 'react-icons/fa';
import { ImPriceTag } from 'react-icons/im'
import { Link } from 'react-router-dom';
import { URL_Local, URL } from '../API_URL/ApiUrl';


export const CardH = (props) => {
    return (
        <div onClick={props.upd} className='containerCardH' style={{cursor: "pointer"}}>
            <div className='cardH'>
                <div className='cardImg1'>
                    <img src={`${URL_Local}/image/` + props.images } alt="img" width="100%" height="100%"/>
                </div>
                <div className='cardhBody'>
                    <p className='cardTitle'>{props.title}</p>
                    <span className='cardDetail'>
                        {props.desc}
                    </span>
                    <Link to={"/equipement/1"} className='cardBtn'>Tout voir</Link>
                </div>
            </div>
        </div>
    );
};

export const CardH1 = (props) => {
    return (
        <div onClick={props.upd} className='containerCardAdmin' style={{cursor: "pointer"}}>
            <div className='cardH'>
                <div className='cardImg1'>
                    <img src={`${URL_Local}/image/` + props.images } alt="img" width="100%" height="100%"/>
                </div>
                <div className='cardhBody'>
                    <p className='cardTitle'>{props.title}</p>
                    <span className='cardDetail'>
                        {props.desc}
                    </span>
                    <Link to={"/materiel"} className='cardBtn'>Tout voir</Link>
                </div>
            </div>
        </div>
    );
};


export const Card1 = (props) => {
    return (
        <div onClick={props.upd} className='containerCardH1'>
            <div className='cardH2'>
                <div className='cardImg1'>
                    <img src={`${URL_Local}/image/` + props.images } alt="img" width="100%" height="100%"/>
                </div>
                <div className='cardhBody'>
                    <p className='cardTitle'>{props.title}</p>
                    <span className='cardDetail'>
                        {props.desc}
                    </span>
                </div>
            </div>
        </div>
    );
};

export const Card2 = (props) => {
    return (
        <div onClick={props.upd} className='containerCardH2'>
            <div className='cardH1'>
                <div className='cardImg1'>
                    <img src={`${URL_Local}/image/` + props.images } alt="img" width="100%" height="100%"/>
                </div>
                <div className='cardhBody2'>
                    <Link to={'/equipement/2'} style={{textDecoration: "none", color: "#FFFFFF"}} className='cardBtn2'>
                        Voir le groupe
                        <FaAngleRight size={20} color="#FFFFFF"/>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export const Card3 = (props) => {
    return (
        <div onClick={props.upd} className='containerCardH1'>
            <div className='cardH3'>
                <div className='cardImg1'>
                    <img src={`${URL_Local}/image/` + props.images } alt="img" width="100%" height="100%"/>
                </div>
                <div className='cardhBody'>
                    <p className='cardTitle'>{props.title}</p>
                    <span className='cardDetail'>
                        {props.desc}
                    </span>
                </div>
            </div>
        </div>
    );
};

export const CardMeuble = (props) => {
    return (
        <Link  to={`/materiel/detail/${props.idArt}`} key={props.id} className='containerCardMeuble'>
            <div className='cardHMeuble'>
                <img src={`${URL_Local}/image/` + props.images } alt="Image 0" width="100%" height="100%" />
                {
                    props.statut === "Disponible" ?  <div className='statut'>{props.statut}</div> 
                    :
                    <>
                        {
                            props.statut === "Réservé" && <div className='statut1'>{props.statut}</div>
                        }
                        {
                            props.statut === "Vendu" && <div className='statut2'>{props.statut}</div>
                        }
                    </>
                }
            </div>
            <div className='cardhBody2'>
                <div className='priceMeuble'>
                    {props.nom}
                </div>
                <div className='priceMeuble'>
                    <ImPriceTag size={16} color="#232F3F"/>
                    {props.prix} F FCFA
                </div>
            </div>
            <Link 
                //to={'/panier'} 
                style={{ textDecoration: "none" }}
                className={props.class}
                onClick={props.addpanier}
            >
                {props.nompanier}
            </Link>
        </Link>
    );
};

export const CardListMeuble = (props) => {
    return (
        <div onClick={props.upd} className='containerCardMeubleH'>
            <h3>{props.title}</h3>
            <p>
                {props.desc}
            </p>
            <Link to={"/equipement"} className='cardBtn'>Voir toute la liste</Link>
        </div>
    );
};