import { ADD_ITEM, REMOVE_ITEM, RESET_ITEMS, CHANGE_THEME } from "../ActionTypes";

// export const addItemToCard = data => ({
//   type: ADD_ITEM,
//   payload: data
// });

// export const removeItemToCard = index => ({
//   type: REMOVE_ITEM,
//   payload: index
// });

// export const changeTheme = type => ({
//   type: CHANGE_THEME,
//   payload: type
// });

export const addItemToCard = (item) => {
  return {
    type: ADD_ITEM,
    item,
  };
};

export const removeItemToCard = (index) => {
  return {
    type: REMOVE_ITEM,
    index,
  };
};

export const changeTheme = type => ({
  type: CHANGE_THEME,
  payload: type
});

export const resetItems = () => ({
  type: RESET_ITEMS,
});