import React, { useState, useEffect } from 'react';
import './styleContact.css'
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import { Form, Button } from 'react-bootstrap';
/* import OpenLayersExample from './OpenLayersExample'; */
import { apiContactMail, apiApropos, entete, notify } from '../../Components/API_URL/ApiUrl';
import { ToastContainer } from 'react-toastify';


const Contacte = () => {
    const [description, setDescription] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const position = [51.505, -0.09]

    const handleSubmit = (event) => {
        const id = localStorage.getItem('id');
        event.preventDefault();
        const nom = event.target.nom.value;
        const email = event.target.email.value;
        const sujet = event.target.sujet.value;
        const message = event.target.message.value;
    
        // Envoi des données du formulaire à l'endpoint Node.js en utilisant Axios
        apiContactMail.post('/', {
            nom: nom,
            email: email,
            sujet: sujet,
            message: message,
            userID: id
        })
        .then(response => {
            console.log(response.data); // Affichez la réponse du serveur dans la console
            // Effectuez les actions supplémentaires nécessaires en cas de succès de l'envoi du message
            event.target.nom.value = '';
            event.target.email.value = '';
            event.target.sujet.value = '';
            event.target.message.value = '';
            notify('success', "Mail envoyé")
        })
        .catch(error => {
            console.error(error); // Affichez les erreurs dans la console
            // Effectuez les actions supplémentaires nécessaires en cas d'échec de l'envoi du message
        });
    }
    
    // Afficher le contenu de A propos
    const allapropos = async () => {
        let token = localStorage.getItem('token');
        try {
            apiApropos.get('/1', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => {
                    console.log(res.data.data)
                    setDescription(res.data.data.description);
                    setEmail(res.data.data.email);
                    setTel(res.data.data.tel);
                })
        } catch (error) { throw error; }
    };

    useEffect(() => {
        allapropos();
    }, []);


    return (
        <div className='containerMateriel'>
            <div className='header'>
                <Head/>
                <Header/>
                <NavBar/>
            </div>

            <div className='body'>
                <div className='containerPanelFlash'>
                    <p className='panelFlash'>Nous-contactez</p>
                </div>
                <ToastContainer 
                    position="top-right"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className='containerContact'>
                    <div className='containerLeft'>
                        <div className='containerMap'>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44574.59343074373!2d-1.5303821983124832!3d12.353820017004937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xe2ebfef2c4dbedd%3A0x1efcd58a60a25d7b!2sAGS%20Faso!5e0!3m2!1sfr!2suk!4v1688627601915!5m2!1sfr!2suk"
                                width="600"
                                height="450"
                                style={{ border: '0' }}
                                allowFullScreen
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />
                        </div>
                        <div style={{textAlign: "justify", padding: "20px 0px"}}>
                        <p>
                            {description}
                        </p>
                        </div>
                        <div className='containerAdresse'>
                            <div className='adresse'>
                                <h4>ADRESSE PHYSIQUE</h4>
                                <span>Ouagadougou</span>
                                <span>Burkina Faso</span>
                            </div>
                            <div className='adresse'>
                                <h4>ADRESSE EMAIL</h4>
                                <span>{email}</span>
                            </div>
                            <div className='adresse'>
                                <h4>APPEL & WHATSAPP</h4>
                                <span>{tel}</span>
                            </div>
                        </div>
                    </div>
                    <div className='containerRight1'>
                        <h1 style={{marginBottom: "2px"}}>Contactez-nous</h1>
                        <h5>Africa Global Service</h5>
                        <form className='formulaire' onSubmit={handleSubmit}>
                            <Form.Group style={{width: "100%"}} controlId="formBasicNom">
                                <Form.Control type="text" size="lg" placeholder="Nom complet" name="nom" className='contactinput'/>
                            </Form.Group>
                            <Form.Group style={{width: "100%"}} controlId="formBasicEmail">
                                <Form.Control type="email" size="lg" placeholder="Votre adresse email" name="email" className='contactinput'/>
                            </Form.Group>
                            <Form.Group style={{width: "100%"}} controlId="formBasicSujet">
                                <Form.Control type="text" size="lg" placeholder="Sujet" name="sujet" className='contactinput'/>
                            </Form.Group>
                            <Form.Group style={{width: "100%"}} controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" placeholder='Votre message' rows={6} name="message" className='contactinput' style={{borderRadius: "20px"}}/>
                            </Form.Group>
                            <div style={{width: '100%', display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                                <Button variant="danger" className='w-50 fw-bold contactinput' type="submit">
                                    Envoyer le message
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className='footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default Contacte;