import React, { useState, useEffect } from 'react';
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';
import Logo from "../../assets/AGS_logo_derive.png";
import { apiApropos, entete } from '../../Components/API_URL/ApiUrl';


const Apropos = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");

    // Afficher le contenu de A propos
    const allapropos = async () => {
        let token = localStorage.getItem('token');
        try {
            apiApropos.get('/1', {
                headers: {
                    entete
                }
            })
                .then(res => {
                    console.log(res.data.data)
                    setTitle(res.data.data.title);
                    setDescription(res.data.data.description);
                    setEmail(res.data.data.email);
                    setTel(res.data.data.tel);
                })
        } catch (error) { throw error; }
    };


    useEffect(() => {
        allapropos();
    }, []);


    return (
        <div className='containerHome'>
            <div className='header'>
                <Head/>
                <Header/>
                <NavBar/>
            </div>

            <div className='body'>
                <div className='containerPanelFlash'>
                    <p className='panelFlash'>A propos</p>
                </div>
                <div className='containerCardAbout'>
                    <div className='aboutLeft'>
                        <p className='aboutTitle'>{title}</p>
                        <p>
                            {description}
                        </p>
                        <br/>
                    </div>
                    <div className='aboutRight'>
                        <div className='about'>
                            <p>Informations de constact</p>
                            <img src={Logo} alt='img' width="262px" height="112px"/>
                            <span>E-mail</span>
                            <p>{email}</p>
                            <span>Numéro téléphonique/Whatsapp</span>
                            <p>{tel} </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default Apropos;

/*
  <p>
Notre entreprise a été créée il y a [nombre d'années] avec pour objectif de devenir un acteur majeur dans le domaine de la vente de matériel immobilier. Depuis sa création, nous avons su développer une expertise solide dans le secteur immobilier, en proposant des services de qualité et en établissant des relations de confiance avec nos clients.
<br/><br/>
Notre équipe de direction est composée de professionnels expérimentés dans le domaine immobilier, qui partagent une passion commune pour la satisfaction du client et la réussite de chaque projet. Notre équipe met tout en œuvre pour offrir à nos clients une expérience exceptionnelle, en les accompagnant à chaque étape du processus de vente de matériel immobilier.
Nous nous appuyons sur des valeurs solides telles que l'éthique des affaires, la transparence et le professionnalisme. Notre culture d'entreprise est orientée vers la satisfaction du client, en mettant toujours leurs besoins au cœur de nos préoccupations. Nous nous engageons à fournir un service de qualité et à respecter les normes les plus élevées de l'industrie immobilière.
Au fil des années, nous avons réalisé de nombreux projets immobiliers réussis et avons établi des relations de confiance avec nos clients. 
<br/><br/>
Nous sommes fiers de nos références et de notre expertise dans le domaine, ce qui témoigne de notre compétence et de notre crédibilité en tant que professionnel de la vente de matériel immobilier.
Notre processus de vente est conçu pour être efficace et transparent. De la recherche des propriétés à la négociation des contrats, en passant par la gestion des transactions, notre équipe vous accompagne à chaque étape du processus pour vous assurer une expérience fluide et sans souci.
<br/><br/>
Nous offrons une large gamme de services, allant de la vente de biens immobiliers neufs ou d'occasion, à la gestion de propriétés, en passant par l'estimation de la valeur des biens. Notre offre diversifiée nous permet de répondre aux besoins variés de nos clients et de leur offrir des solutions sur mesure.
La satisfaction de nos clients est notre priorité absolue. Nous sommes fiers des témoignages positifs que nous recevons de nos clients satisfaits, qui témoignent de notre engagement envers la qualité de service et de notre expertise dans le domaine immobilier.
Nous sommes également fiers de nos partenariats et affiliations dans le secteur immobilier. Nous collaborons avec d'autres entreprises du secteur, des associations professionnelles, ainsi que des certifications et licences pour continuer à nous améliorer et à offrir les meilleurs services possibles à nos clients.
</p>

*/