import React, { useState ,useEffect } from 'react';
import './styleNavbar.css'
import { NavLink } from 'react-router-dom';
import { FaFingerprint } from "react-icons/fa"
import { ImHome } from 'react-icons/im';
import { MdFavorite, MdPermContactCalendar } from 'react-icons/md';
import { TbTruckDelivery } from 'react-icons/tb';
import { AiTwotoneBuild } from 'react-icons/ai';
import { RxDashboard } from 'react-icons/rx';
import { FaUserAlt } from 'react-icons/fa';
import {CgLogOut } from 'react-icons/cg';
import { Divider } from 'rsuite';
import { apiCategorie, entete, apiSousCat } from '../API_URL/ApiUrl';


const Menu = ({ isMenuOpen }) => {
    let token = localStorage.getItem('token');
    let email = localStorage.getItem('email');
    let type = localStorage.getItem('type');
    let nom = localStorage.getItem('nom');
    let prenom = localStorage.getItem('prenom');
    const [categorie, setCategorie] = useState([]);
    const [sousCat, setSousCat] = useState([]);

    let linkStyle = ({ isActive }) => {
        return(
            isActive? {
                background: "#111439",
                color: "#FFFFFF"
            }
            :undefined
        )
    }

    // Fonction pour récupérer les catégories
    const allcategorie = async () => {
        try {
            apiCategorie.get('/', {
                headers: {
                    entete
                }
            })
            .then(res => {
                console.log("CATTTTT"+res.data.data)
                setCategorie(res.data.data);
            })
        } catch (error) { throw error; }
    };

    // Fonction pour récupérer les sous-catégories en fonction de la catégorie sélectionnée
    const allsouscategorie = async (categorieId) => {
        try {
            apiSousCat.get(`/`, {
                headers: {
                    entete
                }
            })
            .then(res => {
                setSousCat(res.data.data);
            })
        } catch (error) { throw error; }
    };


    useEffect(() => {
        allcategorie();
        allsouscategorie();
    }, []);

    return (
        <div className={`app-menu ${isMenuOpen ? "menu-open" : ""}`}>
            <div className='containerHamburger'>
                <div className='hamberger'>
                    <div id='logo'></div>
                    <br/><br/>
                    {
                        token &&
                        <NavLink 
                            to={`/profile`} 
                            className='navBar'
                            style={linkStyle}
                        >
                            <div className='containerIcon' style={{border: "1px solid #FFFFFF"}}>
                                <FaUserAlt size={20} color='#FFFFFF'/>
                            </div>
                            <div className='containerText'>
                                <span>{nom} {prenom}</span>
                                <span>{email}</span>
                            </div>
                        </NavLink>
                    }
                    {
                        type ==="admin" || type ==="superadmin" && 
                        <NavLink 
                            to={`https://admin.africaglobaleservice.com`} 
                            className='navBar'
                            style={linkStyle}
                        >
                            <RxDashboard size={25} color='#FFFFFF'/>
                            Tableau de bord
                        </NavLink>

                    }
                    <NavLink 
                        to={`/`} 
                        className='navBar'
                        style={linkStyle}
                    >
                        <ImHome size={25} color='#FFFFFF'/>
                        Accueil
                    </NavLink>
                    {
                        categorie.filter( category =>[7, 8, 1].includes(category.id))
                        .map(item => (
                           <NavLink 
                                to={`/equipement/${item.id}`} 
                                className='navBar'
                                style={linkStyle}
                            >
                                <AiTwotoneBuild size={25} color='#FFFFFF'/>
                                {item.nomcat}
                            </NavLink>
                        ))
                    }
                    {
                        token &&
                        <NavLink 
                            to={`/livraison`} 
                            className='navBar'
                            style={linkStyle}
                        >
                            <TbTruckDelivery size={25} color='#FFFFFF'/>
                            Livraison
                        </NavLink>
                    }
                    
                    {
                        token &&
                        <NavLink 
                            to={`/favoris`} 
                            className='navBar'
                            style={linkStyle}
                        >
                            <MdFavorite size={25} color='#FFFFFF'/>
                            Les favoris
                        </NavLink>
                    }
                    <NavLink 
                        to={`/contacte`} 
                        className='navBar'
                        style={linkStyle}
                    >
                        <MdPermContactCalendar size={25} color='#FFFFFF'/>
                        Nous-contactez
                    </NavLink>

                    <Divider></Divider>

                    {
                        token?
                        <NavLink 
                            to={`/auth`} 
                            className='navBar'
                            style={linkStyle}
                        >
                            <CgLogOut size={25} color='#FFFFFF'/>
                            Se deconnecter
                        </NavLink>
                        :
                        <NavLink 
                            to={`/auth`} 
                            className='navBar'
                            style={linkStyle}
                        >
                            <FaFingerprint size={25} color='#FFFFFF'/>
                            Se connecter
                        </NavLink>
                    }
                </div>
                <div className='containerMenuFooter'>
                    <div className="compte">
                        <NavLink className='navHead'>Location</NavLink>
                        <NavLink className='navHead'>A propos de nous</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;