import React, { useState, useEffect } from 'react';
import '../Livraison/styleLivraison.css';
import './styleFacture.css';
import Head from '../../Components/Head/Head';
import Header from '../../Components/Header/Header';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import { Modal, Table } from 'react-bootstrap';
import { Badge } from 'rsuite';
import { apiPanier, entete, apiFacture, notify, fCurrencyDot } from '../../Components/API_URL/ApiUrl';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import Kkia from '../Paiement/Kkia';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';


const Facture = () => {
    const [status, setStatus] = useState();
    const [search, setSearch] = useState("");
    const [livraison, setLivraison] = useState([]);
    const [livraisonInfo, setLivraisonInfo] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [etat, setEtat] = useState("");
    const [chargeId, setChargeId] = useState(null);
    const [userID, setUserID] = useState(null);

    const handleSearchInput = (e) => {
        setSearch(e.target.value);
    };

    const id = localStorage.getItem('id');

    // Afficher le contenu facture
    const allAchatByIdUser = async () => {
        let id = localStorage.getItem('id');
        try {
            const response = await apiPanier.get(`/${id}/En attente`, {
                headers: {
                    entete
                }
            });
      
            console.log(response.data.paniers);
            console.log(response.data.paniers[0].PanierInfoSup);
      
            setLivraison(response.data.paniers);
            setUserID(response.data.paniers[0].userID);
            setLivraisonInfo(response.data.paniers[0].PanierInfoSup);
            setUserInfo(response.data.paniers[0].User);
            setEtat(response.data.paniers[0]);
        } catch (error) {
          throw error;
        }
    };    
    const createdAt = new Date(userInfo.createdAt);
    const formattedDate = createdAt instanceof Date && !isNaN(createdAt) ? createdAt.toISOString().split('T')[0] : '';
 

    const ajouterFacture = async (e) => {
        e.preventDefault();
        e.persist();
        let token = localStorage.getItem('token');
        let id = localStorage.getItem('id');
        console.log("object + id")
        const formattedDate = createdAt.toISOString().split('T')[0].substring(2).replace(/-/g, '');
        let reference = `FAC/${userInfo.id}-${localStorage.getItem('id')}/00${livraisonInfo.id}/${formattedDate}`;
        // console.log(email, password);
        try {
            await apiFacture.post('/', {
                ref_facture: reference,
                nomcomplet: `${userInfo.nom} ${userInfo.prenom}`,
                numero: userInfo.telephone,
                email: userInfo.email,
                date: createdAt.toISOString().split('T')[0],
                userID : id,
                etat_fact: "Valider",
                panierinfosupID: livraisonInfo.id
            },
            {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                notify('success', "Votre facture a été ajouté avec succès")
                window.location.href = "/commande"
            }).catch((err) => {
                console.log(err);
                notify('warning', "Une erreur s'est produite. Réésayez plus tard.")
            })
        } catch (error) { 
            notify('', "Echec lors de l'ajout.")
        }
    }; 


    useEffect(() => {
        allAchatByIdUser();
    }, []);

    const gradientStyle = {
        // background: 'linear-gradient(to right, #00b09b, #96c93d)',
        background: 'linear-gradient(to right, #ff4e50, #f9d423)',
        color: '#fff',
        padding: '2px 10px',
    };

    return (
        <div className='containerMateriel'>
            <div className='header'>
                <Head/>
                <Header 
                    search = {search}
                    handleSearchInput = {handleSearchInput}
                />
                <NavBar/>
            </div>
            <ToastContainer 
                position="top-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {
                userID == id ?
                <div className='bodyAdmin' style={{width: "100%"}}>
                    <div className="headerFacture">
                        <div style={{width: "100%", textAlign: "center"}}>
                            <h1 style={{color: "rgba(178,127,65,255)", fontWeight: "bolder"}}>FACTURE</h1>
                        </div>
                        <div className="infoUserFacture">
                            <h1 style={{color: "rgba(178,127,65,255)", fontWeight: "bolder"}}>{userInfo.nom} {userInfo.prenom}</h1>
                            <span>{userInfo.telephone}</span>
                            <span>{userInfo.email}</span>
                        </div>
                        <div>
                            <h1 style={{color: "rgba(178,127,65,255)", fontWeight: "bolder"}}>{formattedDate}</h1>
                        </div>
                    </div>

                    <div style={{ width: "100%", textAlign: "center", fontWeight: "bolder" }}>
                        <Badge
                            style={gradientStyle}
                            content={etat.etat === "En attente" ? "Facture non validée" : "Facture validée"}
                        />
                    </div>


                    <div style={{padding: "10px 0px",}}>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th style={{padding: "8px 5px"}}>#</th>
                                    <th style={{padding: "8px 5px"}}>DESIGNATION</th>
                                    <th style={{padding: "8px 5px"}}>QUANTITE</th>
                                    <th style={{padding: "8px 5px"}}>PRIX UNITAIRE</th>
                                    <th style={{padding: "8px 5px"}}>SOUS TOTALE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    livraison.map((item, index) => {
                                        const prixUnitaire = item.prix_promo !== 0 ? item.prix_promo : item.prix;
                                        return(
                                            <tr>
                                                <td style={{padding: "8px 5px"}}>{index + 1}</td>
                                                <td style={{padding: "8px 5px"}}>{item.nomarticle}</td>
                                                <td style={{padding: "8px 5px"}}>{item.qte}</td>
                                                <td style={{padding: "8px 5px"}}>{fCurrencyDot(prixUnitaire)} F CFA</td>
                                                <td style={{padding: "8px 5px"}}>{fCurrencyDot(item.totalByArcticle)} F CFA</td>
                                            </tr>
                                        );
                                    })
                                }
                                <tr>
                                    <td colSpan={4}>
                                        <h2 style={{fontWeight: "bolder"}}>
                                            Total
                                        </h2>
                                    </td>
                                    <td>
                                        <h2 style={{fontWeight: "bolder"}}>
                                            {fCurrencyDot(livraisonInfo.preTotal)} F CFA
                                        </h2>
                                    </td>
                                </tr>
                                {/*<tr>
                                    <td colSpan={2}>
                                        <h2 style={{fontWeight: "bolder"}}>
                                            Total avec prix d'expédition 
                                        </h2>
                                    </td>
                                    <td colSpan={2}>
                                        <label style={{fontWeight: "10", fontSize: "10px", textAlign: "center", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            Prix d'expédition: {livraisonInfo.prixExpediton} Fcfa
                                        </label>
                                    </td>
                                    <td>
                                        <h2 style={{fontWeight: "bolder"}}>
                                            {livraisonInfo.prixTotal}
                                        </h2>
                                    </td>
                                </tr> */}
                            </tbody>
                        </Table>
                    </div>
                    <div className="headerFacture">
                        <Link className="btnFacture" to={'/livraison'} style={{background: "gray", textDecoration: "none", color: "#FFFFFF"}}>
                            <BsChevronLeft/>
                            Retour
                        </Link>
                       {/* {etat.etat === "En attente" ? 
                            <button className="btnFacture" style={{background: "red"}}>
                                <MdDelete/>
                                Annuler
                            </button>
                            :
                            ""
                        } */}
                        {etat.etat === "En attente" ? 
                            <button className="btnFacture" onClick={ajouterFacture}>
                                Valider et envoyer à la livraison
                                <BsChevronRight/>
                            </button>
                            :
                            ""
                        }
                    </div>
                </div>
                :
                <div style={{height: "40vh"}}></div>
            }
            <br/>
            <div className='footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default Facture;